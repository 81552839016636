import React from 'react'
import { PAYMENT_CONDITION } from '../../../constant/constant';
import OptionService from '../../../service/Options.service';
import { AutoComplete } from 'antd';
import { filterComplete } from '../../../utils/util';


const optrequest = OptionService();
export default function MyAutoComplete({onChange, value = null}) {
    const [optPaymentCond, setOptPaymentCond] = React.useState([]);

    const handleChange = (e, res) => {
      if( typeof onChange === "function") {
        onChange(e, res);
      }
    }

    React.useEffect( () => {
        const initeil = async () => {
            const [
                optPaymentCondRes,  
            ] = await Promise.all([
                optrequest.optionsPurchaseOrder({p:"payment-condition-option"}), 
            ]);

            const paymentCond = optPaymentCondRes.data.data;
            const optPaymentCondDup = [ ...new Set([ 
              ...(PAYMENT_CONDITION.map(m => JSON.stringify(m))), 
              ...(paymentCond.map(m => JSON.stringify(m))) 
            ]) ]
            setOptPaymentCond( optPaymentCondDup.map( m => JSON.parse(m) ).sort((a, b) => (b.label).localeCompare(a.label) ) ); 
        }

        initeil(); 
        return () => {};
    }, []);
  return (<>
    <AutoComplete
        value={value}
        style={{ height:40, width:'100%' }}
        options={optPaymentCond}
        filterOption={filterComplete}
        placeholder='กรอกเงือนไขการชำระเงิน'
        onChange={handleChange}
        allowClear
    >
    </AutoComplete> 
  </>)
}
