export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const formatMoney = (amount, decimalCount) => {
  try {
    let decimal = ".",
      thousands = ",";
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
};

export const PROVINCE_OPTIONS = [
  { label: "กรุงเทพมหานคร", value: "กรุงเทพมหานคร", initial: "กทม" },
  { label: "กระบี่", value: "กระบี่", initial: "กบ" },
  { label: "กาญจนบุรี", value: "กาญจนบุรี", initial: "กจ" },
  { label: "กาฬสินธุ์", value: "กาฬสินธุ์", initial: "กส" },
  { label: "กำแพงเพชร", value: "กำแพงเพชร", initial: "กพ" },
  { label: "ขอนแก่น", value: "ขอนแก่น", initial: "ขก" },
  { label: "จันทบุรี", value: "จันทบุรี", initial: "จบ" },
  { label: "ฉะเชิงเทรา", value: "ฉะเชิงเทรา", initial: "ฉช" },
  { label: "ชลบุรี", value: "ชลบุรี", initial: "ชบ" },
  { label: "ชัยนาท", value: "ชัยนาท", initial: "ชน" },
  { label: "ชัยภูมิ", value: "ชัยภูมิ", initial: "ชย" },
  { label: "ชุมพร", value: "ชุมพร", initial: "ชพ" },
  { label: "เชียงราย", value: "เชียงราย", initial: "ชร" },
  { label: "เชียงใหม่", value: "เชียงใหม่", initial: "ชม" },
  { label: "ตรัง", value: "ตรัง", initial: "ตง" },
  { label: "ตราด", value: "ตราด", initial: "ตร" },
  { label: "ตาก", value: "ตาก", initial: "ตก" },
  { label: "นครนายก", value: "นครนายก", initial: "นย" },
  { label: "นครปฐม", value: "นครปฐม", initial: "นฐ" },
  { label: "นครพนม", value: "นครพนม", initial: "นพ" },
  { label: "นครราชสีมา", value: "นครราชสีมา", initial: "นม" },
  { label: "นครศรีธรรมราช", value: "นครศรีธรรมราช", initial: "นศ" },
  { label: "นครสวรรค์", value: "นครสวรรค์", initial: "นว" },
  { label: "นนทบุรี", value: "นนทบุรี", initial: "นบ" },
  { label: "นราธิวาส", value: "นราธิวาส", initial: "นธ" },
  { label: "น่าน", value: "น่าน", initial: "นน" },
  { label: "บึงกาฬ", value: "บึงกาฬ", initial: "บก" },
  { label: "บุรีรัมย์", value: "บุรีรัมย์", initial: "บร" },
  { label: "ปทุมธานี", value: "ปทุมธานี", initial: "ปท" },
  { label: "ประจวบคีรีขันธ์", value: "ประจวบคีรีขันธ์", initial: "ปข" },
  { label: "ปราจีนบุรี", value: "ปราจีนบุรี", initial: "ปจ" },
  { label: "ปัตตานี", value: "ปัตตานี", initial: "ปน" },
  { label: "พะเยา", value: "พะเยา", initial: "พย" },
  { label: "พระนครศรีอยุธยา", value: "พระนครศรีอยุธยา", initial: "อย" },
  { label: "พังงา", value: "พังงา", initial: "พง" },
  { label: "พัทลุง", value: "พัทลุง", initial: "พท" },
  { label: "พิจิตร", value: "พิจิตร", initial: "พจ" },
  { label: "พิษณุโลก", value: "พิษณุโลก", initial: "พล" },
  { label: "เพชรบุรี", value: "เพชรบุรี", initial: "พบ" },
  { label: "เพชรบูรณ์", value: "เพชรบูรณ์", initial: "พช" },
  { label: "แพร่", value: "แพร่", initial: "พร" },
  { label: "ภูเก็ต", value: "ภูเก็ต", initial: "ภก" },
  { label: "มหาสารคาม", value: "มหาสารคาม", initial: "มค" },
  { label: "มุกดาหาร", value: "มุกดาหาร", initial: "มห" },
  { label: "แม่ฮ่องสอน", value: "แม่ฮ่องสอน", initial: "มส" },
  { label: "ยโสธร", value: "ยโสธร", initial: "ยส" },
  { label: "ยะลา", value: "ยะลา", initial: "ยล" },
  { label: "ร้อยเอ็ด", value: "ร้อยเอ็ด", initial: "รอ" },
  { label: "ระนอง", value: "ระนอง", initial: "รน" },
  { label: "ระยอง", value: "ระยอง", initial: "รย" },
  { label: "ราชบุรี", value: "ราชบุรี", initial: "รบ" },
  { label: "ลพบุรี", value: "ลพบุรี", initial: "ลบ" },
  { label: "ลำปาง", value: "ลำปาง", initial: "ลป" },
  { label: "ลำพูน", value: "ลำพูน", initial: "ลพ" },
  { label: "เลย", value: "เลย", initial: "ลย" },
  { label: "ศรีสะเกษ", value: "ศรีสะเกษ", initial: "ศก" },
  { label: "สกลนคร", value: "สกลนคร", initial: "สน" },
  { label: "สงขลา", value: "สงขลา", initial: "สข" },
  { label: "สตูล", value: "สตูล", initial: "สต" },
  { label: "สมุทรปราการ", value: "สมุทรปราการ", initial: "สป" },
  { label: "สมุทรสงคราม", value: "สมุทรสงคราม", initial: "สส" },
  { label: "สมุทรสาคร", value: "สมุทรสาคร", initial: "สค" },
  { label: "สระแก้ว", value: "สระแก้ว", initial: "สก" },
  { label: "สระบุรี", value: "สระบุรี", initial: "สบ" },
  { label: "สิงห์บุรี", value: "สิงห์บุรี", initial: "สห" },
  { label: "สุโขทัย", value: "สุโขทัย", initial: "สท" },
  { label: "สุพรรณบุรี", value: "สุพรรณบุรี", initial: "สพ" },
  { label: "สุราษฎร์ธานี", value: "สุราษฎร์ธานี", initial: "สฎ" },
  { label: "สุรินทร์", value: "สุรินทร์", initial: "สร" },
  { label: "หนองคาย", value: "หนองคาย", initial: "นค" },
  { label: "หนองบัวลำภู", value: "หนองบัวลำภู", initial: "นภ" },
  { label: "อ่างทอง", value: "อ่างทอง", initial: "อท" },
  { label: "อำนาจเจริญ", value: "อำนาจเจริญ", initial: "อจ" },
  { label: "อุดรธานี", value: "อุดรธานี", initial: "อด" },
  { label: "อุตรดิตถ์", value: "อุตรดิตถ์", initial: "อต" },
  { label: "อุทัยธานี", value: "อุทัยธานี", initial: "อน" },
  { label: "อุบลราชธานี", value: "อุบลราชธานี", initial: "อบ" },
];

export const CAR_BRAND_OPTON = [
  {
    value: "BMW",
    label: "BMW",
  },
  {
    value: "HONDA",
    label: "HONDA",
  },
  {
    value: "MAZDA",
    label: "MAZDA",
  },
  {
    value: "TOYOTA",
    label: "TOYOTA",
  },
  {
    value: "NISSAN",
    label: "NISSAN",
  },
  {
    value: "ISUZU",
    label: "ISUZU",
  },
  {
    value: "MITSUBISHI",
    label: "MITSUBISHI",
  },
  {
    value: "ROVER",
    label: "ROVER",
  },
  {
    value: "AUDI",
    label: "AUDI",
  },
  {
    value: "BENZ",
    label: "BENZ",
  },
  {
    value: "VOLKSWAGEN",
    label: "VOLKSWAGEN",
  },
  {
    value: "OPEL",
    label: "OPEL",
  },
  {
    value: "HINO",
    label: "HINO",
  },
  {
    value: "FORD",
    label: "FORD",
  },
  {
    value: "KIA",
    label: "KIA",
  },
  {
    value: "SUZUKI",
    label: "SUZUKI",
  },
  {
    value: "SEAT",
    label: "SEAT",
  },
  {
    value: "VOLVO",
    label: "VOLVO",
  },
  {
    value: "HOLDEN",
    label: "HOLDEN",
  },
  {
    value: "HYUNDAI",
    label: "HYUNDAI",
  },
  {
    value: "DAEWOO",
    label: "DAEWOO",
  },
  {
    value: "DAIHATSU",
    label: "DAIHATSU",
  },
  {
    value: "PEUGEOT",
    label: "PEUGEOT",
  },
  {
    value: "LAND ROVER",
    label: "LAND ROVER",
  },
  {
    value: "CHEVROLET",
    label: "CHEVROLET",
  },
  {
    value: "DATSUN",
    label: "DATSUN",
  },
  {
    value: "FIAT",
    label: "FIAT",
  },
  {
    value: "CITROEN",
    label: "CITROEN",
  },
  {
    value: "JEEP",
    label: "JEEP",
  },
  {
    value: "SSAGYONG",
    label: "SSAGYONG",
  },
  {
    value: "LEXUS",
    label: "LEXUS",
  },
  {
    value: "SUBARU",
    label: "SUBARU",
  },
  {
    value: "RENAULT",
    label: "RENAULT",
  },
  {
    value: "SAAB",
    label: "SAAB",
  },
  {
    value: "MINI",
    label: "MINI",
  },
  {
    value: "PROTON",
    label: "PROTON",
  },
  {
    value: "SKODA",
    label: "SKODA",
  },
  {
    value: "CHRYSLER",
    label: "CHRYSLER",
  },
  {
    value: "WULING",
    label: "WULING",
  },
  {
    value: "HUMBER",
    label: "HUMBER",
  },
  {
    value: "TATA",
    label: "TATA",
  },
  {
    value: "ALFA ROMEO",
    label: "ALFA ROMEO",
  },
  {
    value: "DFM",
    label: "DFM",
  },
  {
    value: "PORSCHE",
    label: "PORSCHE",
  },
  {
    value: "THAIRUNG",
    label: "THAIRUNG",
  },
  {
    value: "VOLK",
    label: "VOLK",
  },
  {
    value: "NAZA",
    label: "NAZA",
  },
  {
    value: "JAGUAR",
    label: "JAGUAR",
  },
  {
    value: "สามล้อ",
    label: "สามล้อ",
  },
  {
    value: "BENTLEY",
    label: "BENTLEY",
  },
  {
    value: "RELY",
    label: "RELY",
  },
  {
    value: "CHERRY",
    label: "CHERRY",
  },
  {
    value: "MG",
    label: "MG",
  },
  {
    value: "MERCEDES-BENZ",
    label: "MERCEDES-BENZ",
  },
  {
    value: "HAMMER",
    label: "HAMMER",
  },
  {
    value: "HAVAL",
    label: "HAVAL",
  },
  {
    value: "NETA",
    label: "NETA",
  },
  {
    value: "ORA",
    label: "ORA",
  },
  {
    value: "BYD",
    label: "BYD",
  },
  {
    value: "TESIA",
    label: "TESIA",
  },
  {
    value: "BOMA",
    label: "BOMA",
  },
  {
    value: "YARIS",
    label: "YARIS",
  },
  {
    value: "TESLA",
    label: "TESLA",
  },
];

export const capitalized = (word) => word.split(/\s|-/).map((w) => w.charAt(0).toUpperCase() + w.slice(1)).join(" ");

export const formatFileSize = (fileSizeInBytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (Number(fileSizeInBytes) === 0) return "0 Byte";
  const i = parseInt(Math.floor(Math.log(fileSizeInBytes) / Math.log(1024)));
  return Math.round(fileSizeInBytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const comma = (number, dmax = 2, dmin = 0) => number.toLocaleString("en-US", { minimumFractionDigits: dmin,  maximumFractionDigits: dmax});

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const filterOption = (input, option) => {  return (String(option?.label ?? "")).toLowerCase().includes((input || "")?.toLowerCase()) };

export const filterComplete = (input, option) => (String(option?.value ?? "").toUpperCase().indexOf(input?.toUpperCase()) !== -1);

export const notEnter = (e) => { (e.key === 'Enter') && e.preventDefault(); }

export const quarterFormat = ( v ) => {
  const quarter = Math.floor(v.month() / 3) + 1;
  return `Q${quarter}`;
}

export const cmConfig = (content, title = "ยืนยันการทำรายการ") =>( {
  title: title,
  content:content,
})

export const jsonParsable = (value) => {
  try { 
    return JSON.parse(value);
  } catch (e) {
    return undefined;
  }
}

export const dateToThaiFormat = (dateString) => {
  // Convert the input date string to a Date object
  const date = new Date(dateString);

  // Define the months in Thai
  const thaiMonths = [
    "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
    "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
  ];

  // Extract the day, month, and year
  const day = date.getDate();
  const month = thaiMonths[date.getMonth()];
  const year = date.getFullYear() + 543; // Convert to Buddhist year

  // Format the date in Thai format
  return `${day} ${month} ${year}`;
}

export const address = ( val ) => {
  const { address, zipcode, district, amphoe, province, tel, fax, email } = val;

  let dist = "";
  dist += !!district ? ` ต.${district}` :'';
  dist += !!amphoe   ? ` อ.${amphoe}` :'';
  dist += !!province ? ` จ.${province}` :'';
  dist += !!zipcode ? ` ${zipcode}` :'';
  dist += !!tel ? ` โทร.${tel}` :'';
  dist += !!fax ? ` แฟกซ์ ${fax}` :'';
  dist += !!email ? ` อีเมล ${email}` :'';

  const addr = `${address || ''}${dist}`; 
  return addr;
}

export const toSingleSpace = (str) => {
  if( !str || !isNaN(str) ) return str; 

  const result = str?.replace(/\s{2,}/g, ' ');

  return result?.trim();
}