/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { QctPageLayout } from '../../components/layout';
import { TbCheck } from "react-icons/tb";
import { Button, message } from 'antd';
import dayjs from "dayjs";
import GoodsReceiptManageForm from './MyManageForm';
import GoodsReceiptManageView from './MyManageView';

import GoodsReceiptService from "../../service/GoodsReceipt.service";
import { formData } from "./model"; 
import { CancelButton } from '../../components/drawer/cancel';
import useConfirm from '../../store/hook/use-confirm.hook';

const formName = "sup-form";
const apirequest = GoodsReceiptService();
function Manage() {
  const confirms = useConfirm();
  const navigate = useNavigate();
  const location = useLocation(); 

  const { config:{ mode, acname, code } } = location.state || { config: null };

  const [formValue, setformValue] = useState(formData); 

  const checkReceiptNotRet = async (list) => {
      if( list?.some( s => (Number(s.price || 0) <= 0 )) ){
        const comfrmed = await confirms.confirm({content:"มีบางรายการที่ราคาสินค้าเป็น 0 ต้องการที่จะทำรายการต่อหรือไม่"}); 
        return comfrmed;
      }else return true;
  }

  const handleSubmit = async (value) =>{ 
    try { 
      const { head, list } = value;

      const isNotYet = await checkReceiptNotRet(list);
      if( !isNotYet ) return;
      // console.log( value );
      // const action = mode === "create" ? apirequest.create(value) : apirequest.update(value);
      // const listAction = list.filter( f => f.amount > 0 );
      await apirequest[mode]({head, list});
      navigate(-1);
      message.success("Request create done.");
    } catch ( er ){
      console.log( er ); 
      message.error("Error request.")
    }
  }

  const handleCancel = async (v) => { 
    // const result = await confirms.deleted();
    // if( !result ) return;
    try { 
      await apirequest.deleted({ comment: v, code });
      navigate(-1);
      message.success("ยกเลิกใบสั่งสินค้าเรียบร้อย");
    } catch ( er ){
      console.log( er ); 
      const { data:{ option } } = er.response;
      message.error(`Error request. ${option}`);
    } 
  }

  useEffect( () => {
    const initeil = async () =>{
      try {
        if( mode !== "create" && !!code){
          const res = await apirequest.get(code);
          const { data:{ head, list } } = res.data; 

          head.grdate = !!head.grdate ? dayjs(head?.grdate) : null;
          head.dndate = !!head.dndate ? dayjs(head?.dndate) : null;
          head.invdate = !!head.invdate ? dayjs(head?.invdate) : null;

          setformValue({ head, list }); 
        } 
      } catch ( er ){
        console.log( er );
        message.error("error request.")
      }
    }
    initeil();
  }, [mode, code]);

  const acButton = (<>
  { mode !== "view" 
    ?<Button 
      icon={<TbCheck style={{fontSize:'1rem'}}/>} 
      className='bn-center bn-primary'
      form={formName}
      htmlType='submit'   
    >ยืนยัน/บันทึก</Button>
    :<CancelButton value={handleCancel} text='ยกเลิกใบรับสินค้า' />
  }
  </>);

  return (<>
    <QctPageLayout title={`ใบรับสินค้า - ${acname}`} back={true} footerRight={acButton} hearderRight={acButton} >
    {mode === "view"
      ? <GoodsReceiptManageView initeial={formValue} />
      : <GoodsReceiptManageForm  formName={formName} submit={handleSubmit} initeial={formValue} />
    }
 
    </QctPageLayout> 
  </>)
}

export default Manage