import { Badge,Typography,Space,Button } from "antd";
import { comma } from '../../utils/util';
import dayjs from 'dayjs';
import { FileSearchOutlined } from "@ant-design/icons";

const calTotalDiscount = (rec) => {
  const total =  Number(rec?.qty ||  0) * Number(rec?.price ||  0);
  // const discount = 1 - ( Number(rec?.discount ||  0) / 100 );
  // return total * discount;
  const discount = Number(rec?.discount ||  0);

  return total - discount;
}

// const cellNoEdit = (clickCell) => ({
//   onCell: (recoed, index) => ({
//     className: `field-edit ${!!recoed?.sub_item ? 'no-expend !bg-gray-200' : ''}`,
//     onClick:()=> (!!clickCell && !recoed?.sub_item) && clickCell(recoed, index)
//   })
// })

export const accessColumn = () => [  
  {
    title: "id",
    dataIndex: "id",
    key: "id",
    width: "20%",
    hidden: true,
  },
  {
    title: "ทะเบียนรถ",
    dataIndex: "carno",
    key: "carno",
    width: "20%",
    hide: false,
  },
  {
    title: "จังหวัด",
    dataIndex: "province",
    key: "province",
    width: "20%",
    hide: false,
  },
  {
    title: "ยี่ห้อ",
    dataIndex: "brand",
    key: "brand",
    width: "20%",
    hide: false,
  },
  {
    title: "รุ่น",
    dataIndex: "car_model",
    key: "car_model",
    width: "20%",
    hide: false,
  },
  {
    title: "สถานะการใช้งาน",
    dataIndex: "active_status",
    key: "active_status",
    width: "25%",
    hide: false,
    render: (data) => (
      <div>
        {data === "Y" ? (
          <Badge status="success" text="เปิดการใช้งาน" />
        ) : (
          <Badge status="error" text="ปิดการใช้การ" />
        )}
      </div>
    ),
  },
];

export const initeial_formData = {
  active_status: null,
  avg_daydistance: null,
  back_tire: null,
  brand: null,
  business_car: null,
  businessno: null,
  car_cc: null,
  car_chassisno: null,
  car_engineno:null,
  car_loading:null,
  car_model:null,
  car_speed:null,
  car_type:null,
  carno:null,
  color:null,
  cuscode:null,
  front_tire:null,
  id:null,
  modelcode:null,
  province:null,
  remark:null,
};

export const columnViewDetail = ({handleView}) => [
  {
    title: "id",
    dataIndex: "id",
    key: "id",
    hidden: true,
  },
  {
    title: "เลขที่ใบรายการซ่อม",
    dataIndex: "rpocode",
    key: "rpocode",
    width: 100, 
    align: "left",
    // ...cellNoEdit(clickCell),
  },
  {
    title: "วันที่ใบรายการซ่อม",
    dataIndex: "rpodate",
    key: "rpodate",
    width: 100,
    align: "left",
    // ...cellNoEdit(clickCell), 
    render: (v) => !!v ? dayjs(v).format("DD/MM/YYYY") : "-",
  },
  {
    title: "เลขไมค์",
    dataIndex: "mile",
    key: "mile", 
    width: 100,
    align: "left", 
    // ...cellNoEdit(clickCell),
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "stname",
    key: "stname", 
    align: "left",
    width: "20%",
    // ...cellNoEdit(clickCell),
  },
  {
    title: "จำนวน",
    dataIndex: "qty",
    key: "qty", 
    width: "10%",
    align: "right",
    className: "!pe-3",
    // ...cellNoEdit(clickCell),
    render: (v) => <>{ comma( Number( v || 0),  2, 2 )}</>,
  },
  {
    title: "ราคา",
    dataIndex: "price",
    key: "price", 
    width: "10%",
    align: "right",
    className: "!pe-3",
    // ...cellNoEdit(clickCell),
    render: (v) => <>{ comma( Number( v || 0),  2, 2 )}</>,
  },
  {
    title: "ราคารวม",
    dataIndex: "total",
    key: "total",
    width: "10%",
    align: "right",
    className: "!pe-4",
    // ...cellNoEdit(clickCell),
    render: (_, rec) => <>{ comma( calTotalDiscount({...rec, qty: rec?.amount || 0 }),  2, 2 )} บาท</>,
  },
  {
    title: "หมายเหตุ",
    dataIndex: "remark",
    key: "remark", 
    align: "center",
    width: "15%",
    // ...cellNoEdit(clickCell),
  },
  {
    title: "Action",
    key: "operation",
    width: 80, 
    fixed: "right",
    render: (text, record) => (
      <Space>
        <Button
          icon={<FileSearchOutlined />}
          className="bn-primary-outline"
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={(e) => handleView(record,record.rpocode)}
          size="small"
        />
      </Space>
    ),
  },
];

//{xs:1, sm:2, md:2, lg:3, xl:4, xxl:4} 
export const carData = (val) => [
  {
    key: 'business_car',
    label: 'ลักษณะรถ',
    children: <Typography.Text>{val?.business_car}</Typography.Text>,
  },
  {
    key: 'car_owner',
    label: "เจ้าของรถ",
    children: <Typography.Text>{val?.car_owner}</Typography.Text>,
  },
  {
    key: 'carno',
    label: 'ทะเบียนรถ',
    children: <Typography.Text>{val?.carno || '\u00A0'}</Typography.Text>,
    span: { xl: 1, xxl: 1 }
    // labelStyle:lableDesStyle
  },
  {
    key: 'province',
    label: 'จังหวัด', 
    children: <Typography.Text>{val?.province || '\u00A0'}</Typography.Text>, 
    span: { xl: 3, xxl: 3 }
    // labelStyle:lableDesStyle
  },
  {
    key: 'brand',
    label: 'ยี่ห้อ', 
    children: <Typography.Text>{val?.brand || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'car_model',
    label: 'รุ่น/ปี', 
    children: <Typography.Text>{val?.car_model || '\u00A0'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'color',
    label: 'สี', 
    children: <Typography.Text>{val?.color || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'front_tire',
    label: 'ลมยางล้อหน้า', 
    children: <Typography.Text>{val?.front_tire || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'back_tire',
    label: 'ลมยางล้อหลัง', 
    children: <Typography.Text className='text-wrap'>{val?.back_tire || '\u00A0'}</Typography.Text>,
  },
  {
    key: 'remark',
    label: 'หมายเหตุ', 
    children: <Typography.Text className='text-wrap'>{val?.remark || '\u00A0'}</Typography.Text>,
  },
];