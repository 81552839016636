/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { QctPageLayout } from '../../components/layout';
import { TbCheck } from "react-icons/tb";
import { Button, message } from 'antd';
import dayjs from "dayjs";
import PurchaseOrderManageForm from './MyManageForm';
import PurchaseOrderManageView from './MyManageView';

import PurchaseOrderService from "../../service/PurchaseOrder.service";
import { TbSearchProvider } from '../../store/context/table-search.context';
import { formData } from "./model";

import useConfirm from "../../store/hook/use-confirm.hook"; 
import { CancelButton } from '../../components/drawer/cancel';
const formName = "sup-form";
const apirequest = PurchaseOrderService();
function Manage() {
  const confirms = useConfirm();
  const navigate = useNavigate();
  const location = useLocation();

  const { config:{ mode, acname, code } } = location.state || { config: null };

  const [formValue, setFormValue] = useState(formData); 
  const handleSubmit = async (value) =>{ 
    try {
      let result = false;
      // const { head } = value;
      if( mode === "update" ){
        result = formValue.head?.approved_status === "Y" ? await confirms.warninged({ content : "การแก้ไขข้อมูลจะต้องมีการอนุมัติใหม่ ต้องการทำรายการต่อหรือไม่" }) : await confirms.saved();
      } 
      
      if( mode === "create"){
        result = await confirms.saved();
      }

      if( !result ) return;
      // console.log( value );
      // const action = mode === "create" ? apirequest.create(value) : apirequest.update(value);
      await apirequest[mode]({...value, id:code});
      navigate(-1);
      message.success("Request create done.");
    } catch ( er ){
      console.log( er ); 
      message.error("Error request.")
    }
  }

  const handleCancel = async (comment) => { 
    const result = await confirms.deleted();
    if( !result ) return;
    try { 
      await apirequest.deleted(comment, code);
      navigate(-1);
      message.success("ยกเลิกใบสั่งสินค้าเรียบร้อย");
    } catch ( er ){
      console.log( er ); 
      const { data:{ option } } = er.response;
      message.error(`Error request. ${option}`);
    } 
  }

  useEffect( () => {
    const initeil = async () =>{
      try {
        if( mode !== "create" && !!code){
          const res = await apirequest.get(code);
          const { data:{ head, list } } = res.data; 

          head.purdate = !!head.purdate ? dayjs(head?.purdate) : null;
          head.duedate = !!head.duedate ? dayjs(head?.duedate) : null;

          setFormValue({ head, list}); 
        } 
      } catch ( er ){
        console.log( er );
        message.error("error request.")
      }
    }
    initeil();
  }, [mode, code]);

  const acButton = (<>
  { mode !== "view" 
    ?<Button 
      icon={<TbCheck style={{fontSize:'1rem'}}/>} 
      className='bn-center bn-primary'
      form={formName} 
      htmlType='submit'   
    >ยืนยัน/บันทึก</Button>
    :<CancelButton value={handleCancel} text='ยกเลิกใบสั่งสินค้า' disabled={!['รออนุมัติ','รอรับสินค้า','ไม่อนุมัติ'].includes(formValue.head?.status)} />  
  }
  </>);  

  return (<>
    <QctPageLayout title={`ใบสั่งซื้อสินค้า - ${acname}`} back={true} footerRight={acButton} hearderRight={acButton} >
    {mode === "view"
      ? <PurchaseOrderManageView initeial={formValue} />
      : (<TbSearchProvider>
          <PurchaseOrderManageForm  formName={formName} submit={handleSubmit} initeial={formValue} />
        </TbSearchProvider>)
    }
 
    </QctPageLayout>  
  </>)
}

export default Manage