import { Select } from 'antd'
import React from 'react'
import { filterOption, PROVINCE_OPTIONS } from '../../../utils/util'

export default function MySelect({name="province", style={}, className="", onChange, value=null }) {
  // const [ valueDefault, setValueDefault ] = React.useState( null );
  // React.useEffect( () => {
  //   setValueDefault( value );
  // }, [value]);

  const handleChange = (e, res) => {
    if( typeof onChange === "function") {
      onChange(e, res);
    }
  }
    return <Select
      defaultValue={value}
      style={{...{ width: '100%', height: '40px' }, ...style}}
      name={name}
      placeholder="เลือกจังหวัด"
      className={className}
      showSearch
      allowClear
      filterOption={filterOption}
      options={PROVINCE_OPTIONS}
      onChange={handleChange}
    />
}
