/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, DatePicker, Flex, Form, Input, message, Popover, Space, Typography } from 'antd';
import { QctPageLayout } from '../../components/layout/index.js';
import { FormSearchValue, TableSearchValue  } from "../../components/form/index.js";
// import { Typography } from 'antd';
import { 
  // accessColumn, 
  accessColumnV2 
} from './model.js';
import useSearch from '../../store/hook/use-search.hook.js';
// import BillingNoteService from "../../service/BillingNote.service.js"; 
import RepairOrderService from "../../service/RepairOrder.service.js"; 
import dayjs from "dayjs"; 
import { BillingPaymentButton } from '../../components/drawer/billing-payment/index.js';
import { TbSearchProvider } from '../../store/context/table-search.context.js';
import { TbFileSearch, TbHelpCircle } from 'react-icons/tb';
// const apirequest = BillingNoteService();

const reprequest = RepairOrderService();
function Access() {
  const navigate = useNavigate(); 
  const stSearch = useSearch("billing-note-access");
  const { criteria:cria, tbparams:parm } = stSearch.gettingSearch();
  const tbparamsDefault = { ...parm, pagination:  {...{ current: 1, pageSize: 10 }, ...parm?.pagination} };
  const criteriaDefault = {
    ...cria,
    bndate: cria?.bndate?.map(m => dayjs(m)) || null,
    duedate: cria?.duedate?.map(m => dayjs(m)) || null,
  } || { };
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  
  const [criteria, setCriteria] = useState({...criteriaDefault});

  const [tbparams, setTbparams] = useState({...tbparamsDefault});
  const [pageValue, setPageValue] = useState({...tbparamsDefault.pagination}); 
 
  const [viewOnly, setViewOnly] = useState(false);
  const [selected, setSelected] = useState(undefined);

  const [dataSelected, setDataSelected] = useState([]);

  const formSearch = (
    <FormSearchValue title='ค้นหา' onValues={(value) => handleCriteria(value)} defaultValue={criteriaDefault} >
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="เลขใบวางบิล" name="bncode" >
          <Input placeholder="ใส่เลขใบวางบิล" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
        <Form.Item label='วันที่ใบวางบิล' name='bndate'>
          <DatePicker.RangePicker placeholder={['เริ่มวันที่', 'ถึงวันที่']} style={{width:'100%', height:40}}  />
        </Form.Item>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
           <Form.Item label='วันที่กำหนดชำระ' name='payment_duedate'>
               <DatePicker.RangePicker placeholder={['เริ่มวันที่', 'ถึงวันที่']} style={{width:'100%', height:40}}  />
           </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="รหัสลูกค้า" name="cuscode" >
          <Input placeholder="ใส่รหัสลูกค้า" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="ชื่อลูกค้า" name="cusname" >
          <Input placeholder="ใส่ชื่อลูกค้า" />
        </Form.Item>
      </Col>
            
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <Form.Item label="ชื่อผู้ติดต่อ" name="contact" >
          <Input placeholder="ใส่ชื่อผู้ติดต่อ" />
        </Form.Item>
      </Col> 
    </FormSearchValue>
  );

  const handleCriteria = (criteria) => {
    setCriteria(criteria);
    setTbparams(page => ({ 
      ...page, 
      pagination: {...page?.pagination, current: 1},
      current: 1
    }));
  }

  const handlePaging = (page) => {  
    setTbparams(page); 
    // setTbparams(page.pagination); 
  }
  
  const handleSearch = () => {
    
    const [bndate_form, bndate_to] = criteria?.bndate?.map( m => dayjs(m).format("YYYY-MM-DD") ) || [];  
    const [duedate_form, duedate_to] = criteria?.duedate?.map( m => dayjs(m).format("YYYY-MM-DD") ) || []; 
    const billing_status = true;
    const newPram = {...criteria, bndate_form, bndate_to, duedate_form, duedate_to};
    const parm = { criteria:newPram, tbparams };
    const customPayload = { criteria:{...newPram, billing_status}, tbparams };
    reprequest.search( customPayload, { ignoreLoading:Object.keys(parm.criteria).length !== 0 } ).then( res => {
      const { data:{ source, tbparams } } = res.data;
      
      stSearch.updateSearch( parm );
      setPageValue(tbparams?.pagination);
      // setPaing( state => ( {...state, ...pagination }) );
      setDataSource(source); 

      const { order, field } = tbparams;
      setTimeout( ()=>{
        setColumns( state => stSearch.columnOrder(state, order, field)); 
      }, 80);
    }).catch( e => console.dir( e ) );
  }

  const handleRowSelect = (event, record, key) => { 
    let newSelectedRecord = [...dataSelected];
    const rowKey = key || "id";
    const recordKey = record[rowKey];
    // console.log( newSelectedRecord, rowKey, recordKey, record);

    if ( newSelectedRecord?.some( s => s[rowKey] === record[rowKey]) ) { 
      const newData = newSelectedRecord.filter(rec => rec[rowKey] !== recordKey);
      // console.log( newData, newSelectedRecord,  recordKey);
      // setDataSelected(newData);
      newSelectedRecord = newData
    } 

    if (event.ctrlKey && event.button === 0) { 
      newSelectedRecord = [ ...new Set(
        ([...newSelectedRecord, record].map( s => JSON.stringify(s)))
      )].map( m => JSON.parse(m));
    } else {  
      newSelectedRecord = [record] || [];  
    }

    // console.log( newSelectedRecord );
    setDataSelected(newSelectedRecord);
    setViewOnly( !!record?.rpocode && !!key );
    setSelected( !!key ? record : undefined ); 
  }

  const handleCreatData = () => {
    const config = { mode:"create", acname: "สร้าง" };
    navigate("manage", {state:{config}});
  }
  
  const handleUpdateData = (code, bncode) => { 
    const config = { mode:"update", acname: `แก้ไข #${bncode?.bncode}`, bncode, code };
    navigate("manage", {state:{config}});
  }
  
  const handleReviewData = () => { 
    const [rec] = dataSelected;
    const config = { mode:"view", acname: `ใบวางบิล #${rec?.rpocode}`, rec, code:rec?.rpocode };
    navigate("/repair-orders/manage", {state:{config}});
  }
  
  const handlePrintsData = (code, bncode) => { 
    // const newWindow = window.open('', '_blank');
    // newWindow.location.href = `/billing-note-print/${code}`;
    const url = `/billing-note-print/${code}/1`;
    const newWindow = window.open('', url, url);
    newWindow.location.href = url;
  }

  const handleBillingPayment = async ( res ) => {
    try { 
      handleSearch();
      setDataSelected([]); 
    } catch ( e ) {
      message.error("เกิดข้อผิดพลาดทำรายการไม่สำเร็จ");
    }

  }
  
  const handleGetPopoverViewMessage = () => {
      if( dataSelected?.length < 1)  return ""
      else if( dataSelected?.length > 1)  return <>สามารถดูข้อมูลได้ทีละรายการเท่านั้น</>
      else return ""
  }

  const handleCustom = ( key, rec, iconStyle, bntStyle ) => {
    // console.log( rec );
    return (<>
      <Popover
        placement="topRight" 
        title={
          !!handleGetPopoverViewMessage() && "แนะนำการใช้งาน" 
        }
        content={handleGetPopoverViewMessage()}
        arrow={{
          pointAtCenter: true,
        }} 
        trigger={"hover"}
      >
          <Button
            icon={<TbFileSearch style={iconStyle} />} 
            style={bntStyle} 
            className='bn-warning-outline' 
            disabled={dataSelected?.length !== 1}  
            onClick={()=>handleReviewData()}
          >
            <Typography.Text>ดูข้อมูล</Typography.Text>
          </Button>      
      </Popover>

      <BillingPaymentButton 
        selects={dataSelected} 
        disabled={dataSelected?.some( s => ['ชำระเงินครบแล้ว'].includes(s?.status) ) || dataSelected?.length < 1 } 
        value={handleBillingPayment}
      />
    </>)
  }

  const isSelected = (record) => dataSelected?.some( s => !!record?.rpocode && s?.rpocode === record?.rpocode );



  useEffect( () => { 
    // console.log( criteriaDefault );
    handleSearch();

    // setMounted(true);
  }, [JSON.stringify(criteria), JSON.stringify(tbparams)]);

  React.useEffect(() => {
    const initeil = async() => {
      

    const { order, field } = tbparamsDefault;
    const cols = accessColumnV2();
    const colOrder = stSearch.columnOrder(cols, order, field);
    setColumns(colOrder);
  }

  initeil();    

  }, []);

  return (<>
  <TbSearchProvider>
    <QctPageLayout title={"บิล/ใบวางบิล"}>
        <Space direction='vertical' className='flex gap-4'>
          { formSearch }
            <TableSearchValue
              title={<>
                <span className='flex gap-3 items-center'>
                  รายการ 
                  <Popover 
                    placement="topLeft" 
                    title="แนะนำการใช้งาน" 
                    content={<>สามารถเลือกหลายรายการได้โดย กดปุ่ม <b>control + click</b> </>}
                    arrow={{
                      pointAtCenter: true,
                    }} 
                  >
                    <TbHelpCircle style={{fontSize: '1.2rem', cursor:'pointer'}} /> 
                  </Popover>
                </span>
              </>}
              pagingDefault={tbparamsDefault?.pagination}
              onPageChange={handlePaging} 
              onCustom={handleCustom}
              notSelect={true}
              tbProps={{
                rowKey:"rpocode",
                dataSource:dataSource,
                columns:columns,
                pagination: pageValue,
                rowClassName:(record) => (isSelected(record) ? 'ant-table-row-selected' : 'asdasd'),
                onRow:(record) => ({
                  onClick: (e) => handleRowSelect(e, record, "rpocode"), 

                }),
              }}
              />
        </Space>
    </QctPageLayout>
  </TbSearchProvider>
  </>)
}

export default Access