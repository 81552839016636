import { requestService as api, getParmeter } from "./Request.service"  
const API_URL = { 
  API_MANAGE: `/billing-payment/manage.php`,
  API_SEARCH: `/billing-payment/search.php`,
};

const BillingPaymentService = () => { 
  
  const create = (parm = {}, config = {}) => api.post(`${API_URL.API_MANAGE}`, parm, config);
  const update = (parm = {}, config = {}) => api.put(`${API_URL.API_MANAGE}`, parm, config);
  const deleted = (parm = {}) => api.delete(`${API_URL.API_MANAGE}?${getParmeter(parm)}`);
  const get = (code) => api.get(`${API_URL.API_MANAGE}?code=${code}`);
 
  const search = (parm = {}, config = {}) => api.post(`${API_URL.API_SEARCH}`, parm, {...config, cancle: true});
  return {
    create,
    update,
    deleted,
    get,

    search,
  };
};

export default BillingPaymentService;