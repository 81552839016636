import React from 'react'
import { Tag } from "antd"
import { MinusCircleOutlined } from '@ant-design/icons';

function TagStatus({data:v}) {
  if( v === 'รอตัดสต๊อก'){
    return <Tag  color="var(--primary)">รอตัดสต๊อก</Tag> 
  }
  else if( v === 'รอชำระเงิน' ){
    return <Tag color="var(--secondary)">รอชำระเงิน</Tag>
  }
  else if( v === 'ชำระเงินไม่ครบ' ) {
    return <Tag color="var(--warning)">ชำระเงินไม่ครบ</Tag> 
  }
  else if( v === 'ชำระเงินครบแล้ว' ) {
    return <Tag color="var(--success)">ชำระเงินครบแล้ว</Tag> 
  }
  else return <Tag icon={<MinusCircleOutlined />} color="default">ไม่พบสถานะ</Tag>
} 

export default TagStatus