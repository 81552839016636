import { Button, Col, Drawer, Flex, Input, Row, Typography } from 'antd';
import React from 'react'; 
import useConfirm from '../../../store/hook/use-confirm.hook';

export default function MyDrawer({show, value, close}) {
    const confirms = useConfirm()
    const headStyle = {
        borderBottom: `2px solid var(--secondary)`
    }

    const bodyStyle = {
        border: `2px solid var(--secondary)`
    }

    const footStyle = {
        borderTop: `2px solid var(--secondary)`
    }
    const [openComment, setOpenComment] = React.useState(show);
    const [comment, setComment] = React.useState(null);


    const handleCancel = () => {
        setComment(null);
        setOpenComment(false);

        // if( typeof close === 'function'){
        //     close( false ); 
        // }
    }

    const handleConfirm = async () => {  
        const result = await confirms.confirm({content: 'คุณต้องการยกเลิกรายการหรือไม่'});

        if( !result ) return;  
        if( typeof value === 'function'){
            value( comment );
            handleCancel();
        }
        setOpenComment(false);
    }

    const commentFooter = (<>
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
            <Col span={12} className='p-0'>
                <Flex gap={4} justify='start'>
                <Button 
                    className='bn-center bn-secondary-outline'
                    onClick={() => handleCancel()}
                >ปิด</Button>
                </Flex>
            </Col>
            <Col span={12} className='p-0'>
                <Flex gap={4} justify='end'>
                    <Button className={`bn-center bn-secondary-outline`} onClick={()=>handleConfirm()} >ยืนยันการยกเลิก</Button>
                </Flex>
            </Col>
        </Row>
    </>);
    return (
        <>
            <Drawer
                title={`เหตุผลในการยกเลิกรายการ`}
                closable={false}
                onClose={()=>handleCancel()}
                open={openComment}
                key="comment"
                footer={commentFooter}
                styles={{ header: headStyle, content:bodyStyle, footer:footStyle }}
                afterOpenChange={(e) => {
                    if(!e){  
                        (typeof close === 'function') && close( false );  
                    }
                }}
            >
                <Typography.Text  >ความคิดเห็น</Typography.Text>
                <Input.TextArea rows={5} onChange={(e) => setComment(e.target.value)} ></Input.TextArea>
            </Drawer>
        </>
    )
}
