/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { AutoComplete, Button, Col, DatePicker, Divider, Flex, Form, Input, InputNumber, message, Radio, Spin } from 'antd';
import { Modal, Row, Space, Table, Typography } from 'antd';
 
import { StockItemsModal } from '../../components/modal/stock-items/index.js';
import { StockForItemsModal } from '../../components/modal/stock';

import { address, comma, filterComplete } from '../../utils/util';

import { columnsDetailsEditable, columnDetail, formData, columnDetailNoQuotation, columnDetailNoQuotationSub } from "./model";
import { ComponentEdit } from '../../components/table/TableEditable';
import { TableSearchValue } from '../../components/form'; 
import { TbExclamationCircle, TbBuildingWarehouse } from 'react-icons/tb';

import { useTbSearchContext } from "../../store/context/table-search.context"
import dayjs from "dayjs";

import OptionService from "../../service/Options.service.js"
import { CustomersInput } from '../../components/modal/customers';
import { QuotationsInput } from "../../components/modal/quotations";
// import { CSSTransition } from 'react-transition-group';
import {MyTransitionSwitch} from '../../components/transitions';
import useConfirm from '../../store/hook/use-confirm.hook.js';
import { CarsInput } from '../../components/modal/cars';
import { PaymentMethodsInput } from '../../components/modal/payment-methods/index.js';
import PaymentMultiple from '../../components/table/payment/PaymentMultiple.jsx';

const DATEFORMAT = 'YYYY/MM/DD';
const optrequest = OptionService();
export default function ManageForm({formName = "form-name", submit, initeial, validate, mode, action = "create"}) { 
    const emptyText = { emptyText:<span>ไม่มีข้อมูลสินค้า</span> };
    const dividerProp = {orientation:"left", style:{marginBlock:10}, className:'!border-black'}; 
    const formRole = { required: true, message: 'กรุณากรอกข้อมูลให้ครบถ้วน!' }; 

    const context = useTbSearchContext();
    const confirms  = useConfirm();

    const [modeQuotation, setModeQuotation] = React.useState(true);
    const [showQuotation, setShowQuotation] = React.useState(true);

    const [showPayment, setShowPayment] = React.useState(true);

    const [form] = Form.useForm();
    const [formValue, setFormValue] = React.useState({});
    const [listSouce, setListSouce] = React.useState([]);
    const [selectedItem, setSelectedItem] = React.useState({});
    const [subExpend, setSubExpend] = React.useState([]); 

    const transRef = React.useRef(null);
    const creditNumberRef = React.useRef(null);

    const [customers, setCustomers] = React.useState({});
    const [customerChoosed, setCustomerChoosed] = React.useState(undefined);
    const [openStockItem, setOpenStockItem] = React.useState(false);

    const [openItems, setOpenItems] = React.useState(false);

    const [optPaymentCond, setOptPaymentCond] = React.useState([]);
    const [optCarBrand, setOptCarBrand] = React.useState([]);
    const [optCarModel, setOptCarModel] = React.useState([]);

    const [isVat, setIsVat] = React.useState(true);

    const [isPayment, setIsPayment] = React.useState(null);
    const [multiPayment, setMultiPayment] = React.useState(null);


    const [initeialed, setIniteialed] = React.useState(false);

    const onFinish = async (values) => {
        try{
            if( listSouce.length < 1 || !handleValidate(formValue, listSouce) ) return;

            if( listSouce?.some( s => Number( s?.price || 0 ) < 1 ) ){
                const result = await confirms.warninged({ content: "มีสินค้าบอกรายการไม่ถูกกำหนดราคา ต้องการทำรายการต่อหรือไม่" });
                if( !result ) return;
            } 

            if(isPayment === "multi" && Number( formValue?.price_total || 0 ) > 0){
                const totalPrice =  Number( formValue?.price_total || 0 );
                const totalPayment = multiPayment?.reduce( (a, v) => a += v?.money, 0);
                
                // if( !!multiPayment?.some(s => s.money < 1) ) {
                //     confirms.warn("จำนวนเงินการชำระเท่ากับ 0 กรุณาตรวจสอบอีกครั้ง");
                //     return;
                // } 

                if( totalPayment !== totalPrice ) {
                    confirms.warn("จำนวนเงินการชำระ ไม่เท่ากับจำนวนเงินการขาย กรุณาตรวจสอบอีกครั้ง");
                    return;
                } 
            }
            
            const result = action !== "stock" ? await confirms.saved() : true;
            if(!!result) {

                const ispayment = !!formValue?.payment_cond ? isPayment : values?.payment_cond;
                const multi_payments = ispayment === "multi" ? multiPayment : null;
                // const customer_type = customers;
                const head = {...formValue, ...values, ispayment, multi_payments};
                
                head.rpodate = !!head?.rpodate ? dayjs(head?.rpodate).format("YYYY-MM-DD") : null;
                // console.log( head );
                const list = [...listSouce];
                submit({head, list});
            }
        } catch ( e ) {
            console.log( e );  
            message.error(`Error request.`);
        }

    };

    const handleClickCell = (record) => {
        // console.log( record)
        context.clickSelect( "stcode", record);
    }

    const handleSave = async (row) => {
        const newData = (r) => {
            
            const itemDetail = [...listSouce];
            const newData = [...itemDetail];
            
            const ind = newData.findIndex((item) => r?.stcode === item?.stcode);
            if (ind < 0) return itemDetail;
            const item = newData[ind];
            const discount = Number( r?.discount || 0); 

            if( Number(r?.cat_stock_by_product || 0) === 1){
                // console.log(r); 
                const { stock } = r; 
                let seq = 1;
                const sub_items = stock?.reduce( (acc, obj, ind) => {
                    const newObj = {
                        ...obj,
                        qty: 1,
                        amount: 1,
                        price: r?.price,
                        rpodetail: `${r?.rpodetail}/${obj?.mfyear}Q${obj?.quarter}`,
                        sub_item: true,
                        discount
                    }

                    return acc.concat([...Array(obj.qty).keys()].map( m => ({...newObj, stcode: `${obj.stcode}/${seq++}`})) ); 
                }, []); 
                r["children"] = sub_items;
                // console.log( [...new Set([...subExpend, r.stcode])] );
                // console.log( sub_items );
                setSubExpend( s => [...new Set([...s, r.stcode])]);
            }

            newData.splice(ind, 1, { ...item, ...r, discount});

            // const price_total = newData.reduce( (a,v) => a += ( (Number(v.qty || 0 ) * Number(v?.price ||  0)) * (1-(Number(v?.discount ||  0)/100) ) ), 0);
            const price_total = newData.reduce( (a,v) => a += ( ( Number(v.amount || 0 ) * Number(v?.price ||  0) ) - Number(v?.discount ||  0) ), 0);
            // const discount_total = newData.reduce( (a,v) => a += Number(v?.discount ||  0), 0);
            const vat = price_total * (isVat ? 0.07 : 0);
            const grand_total = price_total + vat;

            setFormValue(state => ({
                ...state, 
                vat,
                price_total,
                grand_total
            }));

            setSelectedItem({});
            // console.log( newData );
            return newData;
        };

                    
        const { amount, qty } = row; 
        // console.log( { amount, qty } );
        // console.log( Number(qty || 0) > Number( amount || 0 ));
        if( Number(qty || 0) > Number( amount || 0 ) ) {
            const result = await confirms.warninged({title: "จำนวนที่ต้องการน้อยกว่าจำนวนที่เลือกจากสต๊อก จะต้องเลือกจำนวนจากสต๊อกใหม่", content: "ต้องการทำรายการต่อหรือไม่"});

            if(!result) return; 
            row['qty'] = 0;
        }

        if( Number(qty || 0) > 0 &&  Number(qty || 0) < Number( amount || 0 ) ) {
            await confirms.warn("จำนวนที่เลือกจากสต๊อกน้อยกว่าจำนวนที่ต้องการ กรุณาเลือกจำนวนจากสต๊อกอีกครั้ง");
        }

        const data = [...newData(row)];
        setListSouce(data);
        handleValidate(formValue, data);
    };

    const handleDelete = async (key) => {
        const result = await confirms.deleted();
        if( !result ) return;
        try { 
            const newData = listSouce?.filter( soc => soc.stcode !== key)
            setListSouce(newData);
            const price_total = newData.reduce( (a,v) => a += ( ( Number(v.amount || 0 ) * Number(v?.price ||  0) ) - Number(v?.discount ||  0) ), 0);
            // const discount_total = newData.reduce( (a,v) => a += Number(v?.discount ||  0), 0);
            const vat = price_total * (isVat ? 0.07 : 0);
            const grand_total = price_total + vat;

            setFormValue(state => ({
                ...state, 
                vat,
                price_total,
                grand_total
            }));

        } catch ( e ) {
            message.error("เกิดข้อผิดพลาด");
        }
    }

    const handleStockItemsChoosed  = (value) => { 
        const qty = value?.reduce( (a,v) => a += Number(v?.qty || 0), 0 );
        const updateData = {
            ...selectedItem, 
            qty,  
            stock: value
        }
        setSelectedItem(updateData);
        
        handleSave( updateData );
    }

    const handleItemsChoosed  = (value) => { 
        // console.log( value )
        const { customer_type } = customers;
        const newValue = value.map( m =>{ 
            const { last_sale_price_company,  last_sale_price_personal, price } = m;
            const pr = Number( customer_type || 0 ) === 0 ? Number( last_sale_price_personal || 0 ) 
                : Number( customer_type || 0 ) === 1 ? Number( last_sale_price_company || 0 )
                : Number( price || 0 );
            return ({ 
                ...m,  
                amount: Number(m?.qty || 0),
                rpodetail: !!m.material_code ? m.material_code : m.stname,
                price: pr,
            });
        })
        setListSouce(newValue);
    }

    const handleGetPayment = () => { 
        if( isPayment === 'เครดิต') {
            // console.log( creditNumberRef?.current.value );

            const days = creditNumberRef?.current.value;
            if( !days ) throw new Error("กรุณาใส่จำนวนวันสำหรับการชำระแบบเครดิต");
            const payment_cond = !!days ? `${isPayment} ${days} วัน` : null;
            // console.log( payment_cond );
            return payment_cond;
        } else {
            const payment_cond  = isPayment;
            return payment_cond;
        } 
    };

    const handleExtendTires = (r) => {
        const discount = Number( r?.discount || 0);
        if( Number(r?.cat_stock_by_product || 0) === 1){
            // console.log(r); 
            const { stock } = r; 
            let seq = 1;
            const sub_items = stock?.reduce( (acc, obj, ind) => {
                const newObj = {
                    ...obj,
                    qty: 1,
                    amount: 1,
                    price: r?.price,
                    rpodetail: `${r?.rpodetail}/${obj?.mfyear}Q${obj?.quarter}`,
                    sub_item: true,
                    discount
                } 
                return acc.concat([...Array(obj.qty).keys()].map( m => ({...newObj, stcode: `${obj.stcode}/${seq++}`})) ); 
            }, []); 
            r["children"] = sub_items;
            // console.log( [...new Set([...subExpend, r.stcode])] );
            setSubExpend( s => [...new Set([...s, r.stcode])] );
            return r
        } else return r;
    }

    useEffect( () => {  
        const { head, list } = initeial;
        if( mode === "update" && list?.length > 0){
            head.rpodate = !!head?.rpodate ? dayjs(head?.rpodate): null;
            head.qtdate = !!head?.qtdate ? dayjs(head?.qtdate): null;

            const init = {
                ...head,  
                isvat:!(Number( head?.vat || 0) === 0), 
                hasqt:!!head.qtcode,
                payments: head?.ispayment || null,
            };

            setIsPayment(head?.ispayment || null);
            setMultiPayment(head?.multi_payments || []);

            form.setFieldsValue(init);
            setFormValue({...head});

            const newList = list?.map( r => handleExtendTires(r) );
            // console.log( newList );
            setListSouce([...newList]);
            // setIsVat(init.isvat);
            
            // console.log( head );
            const { cuscode,  cusname, customer_type } = head;
            setCustomerChoosed(!!cuscode ? {cuscode, cusname, customer_type} : undefined);
 
       
            setModeQuotation( !!head.qtcode );
            setShowQuotation( !!head.qtcode )
            handleValidate(head, list);
            // console.log( init );
        }
        return () => {};
    }, [initeial]);
 

    useEffect( () => {
        // console.log( isVat );
        const vat = (isVat ? 0.07 : 0);
        setFormValue(state => {
            const total = Number( state.price_total || 0)
            return {
                ...state, 
                vat:vat * total, 
                grand_total: total + (vat * total), 
                vat_percent: vat
            }
        });
    }, [isVat]);


    useEffect( () => {
        // if( mode === "create") {
            // console.log( mode, modeQuotation ); 
            if( mode === "create") {
                const { rpodate } = form.getFieldsValue();
                const emptyValue = {...formData.head, hasqt:modeQuotation};
                context.clearSelect();
                setFormValue({...emptyValue});
                setListSouce([]);
                setCustomers({});
                setSelectedItem({});
                setIsVat(false);
                setIsPayment(null);
                setCustomerChoosed(undefined); 
                form.resetFields();
                if( !modeQuotation ){
                    // setIsPayment(null);
                    // form.setFieldValue( "payments",  'single'); 
                    form.setFieldValue({});
                }                
                form.setFieldValue( "isvat",  false);
                
                // form.setFieldsValue(emptyValue);
                form.setFieldValue( "rpodate",  ( rpodate || dayjs( new Date()) ) );
                
                form.setFieldValue( "hasqt",  modeQuotation); 
                handleValidate({}, []);            
                // }
                
                setTimeout( () => setShowQuotation(modeQuotation), !modeQuotation ? 200 : 0);
            }
    }, [modeQuotation]);

    useEffect( () => {
        const initeil = async () => {
            const [
                optPaymentCondRes, 
                // optCarBrandRes, 
                // optCarModelRes
            ] = await Promise.all([
                optrequest.optionsQuotation({p:"payment-condition-option"}),
                // optrequest.optionsQuotation({p:"brand-car-option"}),
                // optrequest.optionsModel(),
            ]);

            setOptPaymentCond( optPaymentCondRes.data.data );
            // setOptCarModel( optCarModelRes.data.data?.map( m => ({value: m.modelname })) );

            // const b = [ ...new Set( [...(optCarBrandRes.data.data?.map( m => m.value )), ...(CAR_BRAND_OPTON.map( m => m.value ))] )]?.map( v => ({value:v}));
            // setOptCarBrand( b );
        }
        
        initeil(); 
        return () => {};
    }, []);

    const handleCustomer = (val, key, customer_type) => {
        // console.log( val, key, customer_type );
        if(!val) return;
        const { taxnumber, taxno } = val;
        const addr = address(val);
        const valueForm = form.getFieldsValue();
        const newIntieial = { 
            ...valueForm,
            ...val,
            taxno:taxno || taxnumber,
            customer_type,
            address:addr,
        };
        form.setFieldsValue( newIntieial );
        setCustomers( {...val, customer_type} );
        setFormValue( s => ({ ...s, ...newIntieial }) );
    }

    const handleCars = (val) => {
        console.log( customerChoosed, val );
        if(!val) return;
        const valueForm = form.getFieldsValue();
        if( !!customerChoosed ) {
            const { car_no, car_brand, car_modelname: car_model, car_province, car_type, car_color } = val;

            const newIntieial = { 
                ...valueForm,
                ...{ car_no, car_brand, car_model, car_province, car_type, car_color }, 
            };
            setFormValue( s => ({ ...s, ...newIntieial }) ); 
            form.setFieldsValue( newIntieial ); 
        } else {
            const { taxnumber, car_modelname: car_model } = val;
            const addr = address(val);
            const newIntieial = { 
                ...valueForm,
                ...val,
                taxno:taxnumber,
                address:addr,
                car_model,
            };
            setCustomers( {...val} );
            setFormValue( s => ({ ...s, ...newIntieial }) ); 
            form.setFieldsValue( newIntieial );            
        }
    }

    const handleQuotation = (val) => {
        val.qtdate = !!val.qtdate ? dayjs(val.qtdate) : null;
        const { payment_cond } = val;
        const formData = form.getFieldsValue();
        const isp = !!payment_cond ? "single" : null;
        const newData = {
            ...formData, 
            ...formValue, 
            ...val, 
            vat:0, 
            price_total:0, 
            grand_total:0, 
            remark: null,
            payments: isp
        }
        setFormValue(newData);

        // console.log( !!payment_cond)
        setIsPayment(isp);
        // if(!!payment_cond) {
        //     setIsPayment(!!payment_cond ? "single" : null);
        // }
        const { cuscode,  cusname, customer_type } = newData;
        setCustomerChoosed({cuscode, cusname, customer_type})
        setIsVat(Number( newData?.vat_percent || 0 ) > 0);

        form.setFieldsValue(newData);
        
        handleGetQuotationDetail( val?.qtcode );
    }

    const handleGetQuotationDetail = (qtcode) => { 
        optrequest.optionsQuotation({p: "quotation-detail", code: qtcode}).then( res => {
            const { data } = res.data;
            const newData = data?.map( item => ({ 
                ...handleExtendTires(item), 
                rpodetail: item?.qtdetail, 
                qty: Number( item?.cat_count_stock || 0 ) === 0 ? item?.amount : 0 
            }));

            handleValidate(formValue, newData);
            setListSouce( newData );
        }).catch( err => {
            console.log( err )
        })
    }

    const handleItems = (record) => {
        setOpenItems(true); 
    }

    const handleStocked = async (record) => {
        const { amount } = record;
        if( Number(amount || 0) < 1) {
            confirms.warn("กรุณากรอกจำนวนสิ้นค้าที่ต้องการ");
            return;
        }
        setSelectedItem(record);
        setOpenStockItem(true);
    }

    const handleRender = (_, record) => {
        if(!!record?.sub_item) return;
        return (<> {
            (Number(record?.has_stock || 0 ) !== 0 && Number( record?.cat_count_stock || 0 ) === 1 ) && (
                <Typography.Link 
                    className='hover:!underline flex items-center gap-1' 
                    onClick={()=>handleStocked(record)}
                ><TbBuildingWarehouse /> คลิกเลือก</Typography.Link>)

            }
            {(Number( record?.cat_count_stock || 0 ) === 0  ) && ( <Typography.Text className='text-blue-400'>0707</Typography.Text> )}

            {((Number(record?.has_stock || 0 ) === 0 || !record?.stock_qty) && Number( record?.cat_count_stock || 0 ) === 1 ) && ( <Typography.Text className='text-red-600'>ไม่มีสินค้าในสต๊อก</Typography.Text> )}
        </>)
    }

    const handleValidate = (head, list) => { 
        const v_header = Object.values( head )?.length < 1;
        const v_list = list?.length < 1;
        const v_error_list = list?.some( s => { 
            const e_qty = (Number( s?.qty ) !== Number( s?.amount )) && modeQuotation;
            const e_zero = Number( s?.qty || 0 ) < 1;
            const e_detail = !s?.rpodetail;
            // console.log( {e_qty , e_zero , e_detail})
            return e_qty || e_zero || e_detail;
        }) || v_list;
        // const v_zero_qty = list.some( s => Number( s?.qty || 0 ) < 1 );
        // const v_zero_price = list.some( s => Number( s?.v_zero_price || 0 ) < 1 );

        const result  = (v_header || v_list || v_error_list);
        typeof validate === "function" && validate( !result )
        // console.log( v_header && v_list && v_error_list, {v_header , v_list ,v_error_list} );
        return !result;
    }

    const handleExpand = (expanded, record) => {
        if (expanded) {
          setSubExpend( s => [...new Set([...s, record.stcode])]); 
        } else {
          setSubExpend( s => s.filter(key => key !== record.stcode));  
          // setExpandedRowKeys(expandedRowKeys.filter(key => key !== record.key));
        }
    };

    const handlePaymentChange = (e) => {
        const value = e.target.value;
        if( !value ) return;
        // console.log( value )
        if( value === "multi" ){
            setShowPayment( true );
            setFormValue( s => ({...s, payment_cond: 'หลายรูปแบบ' }));
        } else {
            setMultiPayment([]);
            setFormValue( s => ({...s, payment_cond: null }));
            setShowPayment( false );
        }

        setIsPayment(e.target.value)


    }

    const handlePaymentMethod = (v) => {
        // console.log( v );
        const { payment_name } = v;
        setFormValue( s => ({...s, payment_cond: payment_name }));
    }

    const styles = (<> 
        <Divider {...dividerProp}>รูปแบบใบรายการซ่อม</Divider>
        <Row className='!mx-0' gutter={[8,8]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item name="hasqt">
                    <Radio.Group onChange={(e) => setModeQuotation(e.target.value)} className='gap-3 flex-wrap' disabled={mode === "update"}>
                        <Radio value={true}>สร้างจากใบเสนอราคา</Radio>
                        <Radio value={false}>สร้างแบบไม่มีใบเสนอราคา</Radio> 
                    </Radio.Group>
                </Form.Item>
            </Col>           
        </Row>
    </>)

    const information = (<> 
        <Divider {...dividerProp}>ข้อมูลใบรายการซ่อม/ใบเสนอราคา</Divider>
        <Row className='!mx-0' gutter={[8,8]}>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item label="วันที่ใบรายการซ่อม" name="rpodate" rules={[formRole]}>
                <DatePicker style={{ width: "100%", height:40 }} format={DATEFORMAT} placeholder='กำหนดวันที่ใบรายการซ่อม' />
              </Form.Item>
            </Col>
            { !!showQuotation && <>
                <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Form.Item label="เลขใบเสนอราคา" htmlFor="qtcode-1"  name="qtcode" rules={[!customers.cuscode ? formRole : {}]} >
                        <QuotationsInput selected={handleQuotation} placeholder='เลือกใบเสนอราคา' id='qtcode-1' className='!bg-zinc-50 !text-black' value={formValue.qtcode} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Form.Item label="วันที่ใบเสนอราคา" name="qtdate" >
                        <DatePicker 
                            style={{ width: "100%", height:40 }} 
                            format={DATEFORMAT} 
                            placeholder='เลือกวันที่ใบเสนอราคา' 
                            className='!bg-zinc-50 !text-black' 
                            disabled
                        />
                    </Form.Item>
                </Col>  
                <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={6}>
                    <Form.Item label="ออกเอกสารโดย" name="created_name" >
                        <Input disabled placeholder='ออกเอกสารโดย' className='!bg-zinc-50 !text-black' />
                    </Form.Item>
                </Col>              
            </>}
          
        </Row>
    </>)

    const quotation = (<>
        <Divider {...dividerProp}>ข้อมูลใบเสนอราคา</Divider>
        <Row className='!mx-0' gutter={[8,8]}>
            <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <Form.Item label="เลขใบเสนอราคา" htmlFor="qtcode-1"  name="qtcode" rules={[!customers.cuscode ? formRole : {}]} >
                    <QuotationsInput selected={handleQuotation} placeholder='เลือกใบเสนอราคา' id='qtcode-1' className='!bg-zinc-50 !text-black' value={formValue.qtcode} />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <Form.Item label="วันที่ใบเสนอราคา" name="qtdate" >
                    <DatePicker 
                        style={{ width: "100%", height:40 }} 
                        format={DATEFORMAT} 
                        placeholder='เลือกวันที่ใบเสนอราคา' 
                        className='!bg-zinc-50 !text-black' 
                        disabled
                    />
                </Form.Item>
            </Col>  
            <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <Form.Item label="ออกเอกสารโดย" name="created_name" >
                    <Input disabled placeholder='ออกเอกสารโดย' className='!bg-zinc-50 !text-black' />
                </Form.Item>
            </Col>
            {/* <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={6}>
                <Form.Item label="เงือนไขการชำระ" name="payment_cond" >
                    <Input disabled placeholder='เลือกเงือนไขการชำระ' className='!bg-zinc-50 !text-black' />
                </Form.Item>
            </Col> */}
        </Row>
    </>)

    const payment = (<>
        <Divider {...dividerProp}>ข้อมูลการชำระ</Divider> 
            <Row className='!mx-0 relative' gutter={[8,8]}>
                { (!!showQuotation && !formValue?.qtcode) &&  <Spin indicator={<></>} className='absolute w-full h-full z-10 bg-gray-300 opacity-50' />}
                <Col xs={24} sm={12} md={12} lg={12}>
                    <Form.Item label="รูปแบบการชำระ" name="payments" >
                        <Radio.Group  onChange={handlePaymentChange} className='gap-3 flex-wrap items-center' style={{height:40}}> 
                            <Radio value='single' className='flex-grow rep-payment'>
                                <span className='flex items-center w-full '>
                                    <span className='text-nowrap me-6'>รูปแบบเดียว</span>
                                    <PaymentMethodsInput 
                                        className="!text-black" 
                                        selected={handlePaymentMethod} 
                                        value={ isPayment === 'single' ? formValue?.payment_cond : null }
                                        disabled={ ( !isPayment || isPayment !== 'single' ) }
                                    /> 
                                </span>
                            </Radio>
                            <Radio value='multi'>หลายรูปแบบ</Radio>
                        </Radio.Group> 
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}> 
                    { isPayment === "multi" &&  <PaymentMultiple onChange={(v) => setMultiPayment( v )} defaultList={multiPayment} formData={formValue} /> } 
                </Col>
            </Row> 
    </>)

    const customer = (<>
        <Divider {...dividerProp}>ข้อมูลลูกค้า</Divider>
        <Row className='!mx-0' gutter={[8,8]}>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="รหัสลูกค้า" htmlFor="cuscode-1"  name="cuscode" rules={[formRole]} >
                    {/* { !!modeQuotation &&
                        <Input disabled placeholder='ชื่อลูกค้า' id='cuscode-1' className='!bg-zinc-50 !text-black' />
                    }
                    { !modeQuotation && 
                        <CustomersInput selected={handleCustomer} placeholder='เลือกลูกค้า' id='cuscode-1' className='!bg-zinc-50 !text-black' value={formValue.cuscode} />
                    } */}
                    <Input disabled placeholder='ชื่อลูกค้า' id='cuscode-1' className='!bg-zinc-50 !text-black' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={18} lg={18}>
                <Form.Item label="ชื่อลูกค้า" name="cusname" rules={[formRole]} >
                    <Input disabled placeholder='ชื่อลูกค้า' className='!bg-zinc-50 !text-black' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="เลขผู้เสียภาษี/เลขบัตรประชาชน"  name="taxno" >
                    <Input placeholder='เลขผู้เสียภาษี' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="ติดต่อ"  name="contact_name" >
                    <Input placeholder='ติดต่อ' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="เบอร์โทร"  name="contact_tel" >
                    <Input placeholder='เบอร์โทร' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="อีเมล"  name="contact_email" >
                    <Input placeholder='อีเมล' />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
                <Form.Item label="ที่อยู่ลูกค้า"  name="address" rules={[formRole]} >
                    <Input.TextArea placeholder='ที่อยู่' rows={2} />
                </Form.Item>
            </Col>
        </Row> 
    </>);

    const cars = (<>
        <Divider {...dividerProp}>ข้อมูลรถยนต์</Divider>
        <Row className='!mx-0' gutter={[8,8]}>
            <Col xs={24} sm={12} md={6} lg={6}>
                <Form.Item label="ทะเบียนรถ" htmlFor="car_no-1"  name="car_no" rules={!modeQuotation ? [formRole] : [{required:false}]} >
                    { !!modeQuotation && (
                        !!customerChoosed 
                            ? <CarsInput selected={handleCars} placeholder='เลือกทะเบียนรถ' id='car_no-1' className='!bg-zinc-50 !text-black' value={formValue.carno} cus={customerChoosed} />
                            : <Input disabled placeholder='กรอกทะเบียนรถ' id='car_no-1' className='!bg-zinc-50 !text-black' />
                        )
                    }
                    { !modeQuotation && 
                        <CarsInput selected={handleCars} placeholder='เลือกทะเบียนรถ' id='car_no-1' className='!bg-zinc-50 !text-black' value={formValue.carno} />
                    }
                </Form.Item>
            </Col>  
            <Col xs={24} sm={24} md={9} lg={9}>
                <Form.Item label="ยี่ห้อรถ" name="car_brand" >
                    <Input disabled placeholder='กรอกยี่ห้อรถ' />
                    {/* <AutoComplete
                        style={{ height:40, width:'100%' }}
                        options={optCarBrand}
                        filterOption={filterComplete}
                        placeholder='กรอกกรอกยี่ห้อรถ'
                    >
                    </AutoComplete>*/}
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={9} lg={9}>
                <Form.Item label="รุ่น"  name="car_model" >
                    <Input disabled placeholder='กรอกรุ่น' />
                    {/* <AutoComplete
                        style={{ height:40, width:'100%' }}
                        options={optCarModel}
                        filterOption={filterComplete}
                        placeholder='กรอกรุ่น'
                    >
                    </AutoComplete> */}
                </Form.Item>
            </Col>
        </Row> 
    </>);
 
    // const lists = (<div className='bnt'>
    //     <Divider {...dividerProp}>รายละเอียด</Divider> 
    //     <TableSearchValue
    //         title='รายการสินค้า'
    //         notSelect={true}
    //         tbProps={{
    //             components:ComponentEdit,
    //             rowKey:"stcode",
    //             dataSource:listSouce,
    //             columns:columnsDetailsEditable(
    //                 handleSave, 
    //                 columnDetail(handleClickCell, handleRender) 
    //             ),
    //             pagination: false,
    //             locale:{...emptyText},
    //             summary: (record) => totalPrice,
    //             expandable:{
    //                 // defaultExpandedRowKeys: [...subExpend],
    //                 defaultExpandAllRows: true,
    //                 // expandIconColumnIndex: -1,
    //                 // expandedRowKeys: subExpend,
    //             }
    //         }}
    //     /> 
    // </div>);

    const lists =  (<div className='bnt'>
        <Divider {...dividerProp}>รายละเอียด</Divider>
        <TableSearchValue
            title='รายการสินค้า' 
            // notSelect={true}
            onDelete={handleDelete}
            onCreate={handleItems}
            tbProps={{
                components:ComponentEdit,
                rowKey:"stcode",
                dataSource:listSouce,
                columns:columnsDetailsEditable(
                    handleSave, 
                    columnDetailNoQuotation(handleClickCell, handleRender)
                ),
                pagination: false,
                locale:{...emptyText},
                onRow:() => {},
                summary: (record) => totalPrice,
                expandable:{
                    // expandedRowKeys: subExpend,
                    // onExpand: handleExpand
                }
            }}
        />
    </div>)

    const totalPrice = (<>
        <Table.Summary.Row>
            <Table.Summary.Cell colSpan={modeQuotation ? 5 : 5} rowSpan={3} className='!bg-white'>
                <Form.Item name="remark" label="หมายเหตุ" className='!m-0 align-top'> 
                    <Input.TextArea placeholder='กรอกหมายเหตุ' rows={3} className='!resize-none' />
                </Form.Item> 
            </Table.Summary.Cell>
            <Table.Summary.Cell colSpan={2} align='end' className='!pe-3 align-top'>รวมเงินทั้งสิ้น</Table.Summary.Cell>
            <Table.Summary.Cell className='!pe-3 text-end border-right-0 align-top' style={{borderRigth:"0px solid"}} >
              <Typography.Text type="danger">{ comma(Number(formValue?.price_total || 0),2,2) }</Typography.Text>
            </Table.Summary.Cell>
            <Table.Summary.Cell colSpan={2} align='start' className='!pe-3 align-top'>บาท</Table.Summary.Cell>
        </Table.Summary.Row>
        {/* <Table.Summary.Row>
            <Table.Summary.Cell colSpan={2} align='end' className='!pe-3'>ภาษีมูลค่าเพิ่ม { isVat && '(7%)'}</Table.Summary.Cell>
            <Table.Summary.Cell className='!pe-4 text-end border-right-0' style={{borderRigth:"0px solid"}} >
                <Typography.Text type="danger" className='text-nowrap'>{ comma(Number(formValue?.vat || 0),2,2) }</Typography.Text>
            </Table.Summary.Cell>
            <Table.Summary.Cell colSpan={2} align='start' className='!pe-3'>บาท</Table.Summary.Cell>
        </Table.Summary.Row> 
        <Table.Summary.Row>
            <Table.Summary.Cell colSpan={2} align='end' className='!pe-3'>ยอดรวมสุทธิ</Table.Summary.Cell>
            <Table.Summary.Cell className='!pe-4 text-end border-right-0' style={{borderRigth:"0px solid"}} > 
              <Typography.Text type="danger" className='text-nowrap'>{ comma(Number(formValue?.grand_total || 0),2,2) }</Typography.Text> 
            </Table.Summary.Cell>
            <Table.Summary.Cell colSpan={2} align='start' className='!pe-3'>บาท</Table.Summary.Cell>
        </Table.Summary.Row> */}
    </>)

    return (<> 
    <Space direction='vertical' className='w-full' >
        <Form
            form={form} 
            layout='vertical'
            name={formName}
            autoComplete="off"
            className='w-full'
            // onValuesChange={(_, value)=> setFormValue(value)} 
            onFinish={onFinish}
        >
            <div className="" style={{
                minHeight:0, 
                height:'auto',
                transition: 'all 300ms ease',
                transitionDuration: '300ms',
                backgroundColor:'#ff'
            }}>
                {styles}
                {information}
                <MyTransitionSwitch propIn={!!modeQuotation}>
                    {!!showQuotation ? <>{payment}</> : <>{payment}</>} 
                </MyTransitionSwitch>
                {/* {quotation} */}
                <MyTransitionSwitch propIn={!modeQuotation}>
                    {!showQuotation && cars}
                </MyTransitionSwitch>
                {customer}
                <MyTransitionSwitch propIn={!!modeQuotation}>
                    {!!showQuotation && cars}
                </MyTransitionSwitch>      
                <MyTransitionSwitch propIn={!!modeQuotation}>
                    {!!showQuotation ? <>{lists}</> : <>{lists}</>} 
                </MyTransitionSwitch>
            </div>
        </Form>
    </Space> 
    
    { openStockItem && 
        <StockForItemsModal
            show={openStockItem}
            values={handleStockItemsChoosed}
            close={() => setOpenStockItem(false)}
            selected={selectedItem}
            hasQt={modeQuotation}
        />
    }
    
    { openItems && 
        <StockItemsModal
            show={openItems}
            values={handleItemsChoosed}
            close={() => setOpenItems(false)}
            selected={listSouce}
        />
    }
    </>)
}