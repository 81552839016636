import React from 'react'
import { Modal, Card, Form, Typography, Flex, message, Button } from "antd";
import { Row, Col, Space } from "antd";
import { Input } from "antd";
import { TbSearch, TbCar, TbCheck, TbPencilCheck } from "react-icons/tb";
import MyFormManage from "./MyFormManage"

import { TableSearchValue } from '../../form';
import OptionService from '../../../service/Options.service.js';
import useConfirm from '../../../store/hook/use-confirm.hook.js';
// import SupplierService from '../../../service/Supplier.service.js';

import { myModalColumn } from "./model.module.js";
import { useTbSearchContext } from '../../../store/context/table-search.context.js';

const optrequest = OptionService();
// const apirequest = SupplierService();
const tbparamsDefault = { pagination: { current: 1, pageSize: 10 } };
export default function MyModal({show, close, values, cus}) {
    const context = useTbSearchContext();
    const confirms = useConfirm();
    const [form] = Form.useForm();

    const [formManage] = Form.useForm();
    const [openModal, setOpenModal] = React.useState(show);
    const [loading,  setLoading] = React.useState(true); 

    const [openFormManage, setOpenFormManage] = React.useState(false);
    const [manageTitle, setManageTitle] = React.useState(false);

    const [mode, setMode] = React.useState("create");

    const [criteria, setCriteria] = React.useState({});

    const [tbparams, setTbparams] = React.useState({...tbparamsDefault});
    const [pageValue, setPageValue] = React.useState({...tbparamsDefault.pagination});
 

    const [dataSource, setDataSource] = React.useState([]);
    const [dataSelect, setDataSelect] = React.useState({});
    const containerStyle = {
      position: 'relative',
      overflow: 'hidden',
      height: '100%',
    };    

    const handleClose = () =>{ 
        setOpenModal(false);
        // context.clearSelect();
        setTimeout( () => { close(false);  }, 140); 
        //setTimeout( () => close(false), 200 );
    }

    const handleCriteria = (criteria) => { 
        setCriteria({...criteria});
        setTbparams(page => ({ 
          ...page, 
          pagination: {...page?.pagination, current: 1},
          current: 1
        }));
    }

    const handleRowSelect = (record, key) => {
        if( mode === "update" ) return;
        
        if( !key ) {
            setDataSelect({});
            return;
        }
        setDataSelect( {key, record} ); 
    }

    const handleChoosed = () => { 
        const { record } = dataSelect;
        if( !!values ) values( record ); 
        handleClose();
    }

    const handleCreatData = () => {
        setDataSelect({});
        setMode("create");
        setManageTitle("สร้าง - ข้อมูลรถยนต์");
        setOpenFormManage(true);
    }

    const handleUpdateData = (key, record) => {
        setManageTitle("แก้ไข - ข้อมูลรถยนต์"); 
        setMode("update");
        formManage.setFieldsValue( record );
        setOpenFormManage(true);
    }

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const val = await formManage.validateFields();
            const { customer_type } = cus;
            const business_car = Number( customer_type );
            const { record } = dataSelect;
            const data = {
                ...record,
                ...val,
                cuscode: business_car === 0 ? cus.cuscode : null,
                businessno: business_car === 1 ? cus.cuscode : null,
                business_car
            }
            // console.log( data, dataSelect );
            const result = await confirms.saved();
            if( !result ) return;
            const action = mode === "update" ? optrequest.optionsCarsPut : optrequest.optionsCarsPost; 
            await action({data, p: mode }, { ignoreLoading : true });
            handleSearch();
            setOpenFormManage(false);
        } catch (  e ) {
            console.log( e );
            message.error("เกิดข้อผิดพลาด ทำรายการไม่สำเร็จ");
        } finally {
            setLoading(false);
        }

    }


    const handleSearch = React.useCallback(() => { 
      const cond = criteria
      const parm = { criteria:{...cond, cuscode: cus?.cuscode }, tbparams, p:'cars' };
      setLoading(true);
      try { 
        optrequest.optionsCarsPost(parm, { ignoreLoading:true } ).then( res => {
          const { data:{ source, tbparams } } = res.data;
          
          setPageValue(tbparams?.pagination);
          // setPaing( state => ( {...state, ...pagination }) );
          setDataSource(source);

          setLoading(false);
        }).catch( e => {
            console.log( e );
            message.error("เกิดข้อผิดพลาดในการดึงข้อมูล");
            
        })
      } catch(err){
        message.error("เกิดข้อผิดพลาด")
      } finally {
        setLoading(false); 
      }
    }, [criteria, tbparams, cus]);

    React.useEffect(() => {
        handleSearch();
    
    }, [handleSearch]);

    React.useEffect(() => {
        const initeil = async() => { }
        initeil(); 
    }, []);

    const modalTitle = (<>
        <Flex align='center' gap={4}>
            <TbCar style={{fontSize: '1rem'}} />
            <Typography.Text className='ms-1 mb-0'>รายการรถ { !!cus?.cusname ? <>สำหรับลูกค้า {cus?.cusname}</> : "ลูกค้า "} </Typography.Text>
        </Flex>    
    </>);

    const modalSearch = (<>
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
            <Col xs={24} sm={24} lg={24}>
                <Form.Item label="ค้นหา" name='search'  >
                    <Input suffix={<TbSearch />} placeholder='ค้นหาข้อมูล'/>
                </Form.Item>                        
            </Col>  
        </Row>    
    </>);

    const modalFooter = (<> 
        <Row>
          <Col span={24}>{/* Ignore */}</Col>
          <Col span={24}>
            <Flex justify="flex-end">
                { openFormManage 
                    ? <>
                        <Button
                            className="bn-center bn-success-outline min-w-36"
                            icon={<TbPencilCheck />}
                            onClick={() => handleSubmit()}
                        >ยืนยัน/บันทึก</Button>                    
                      </>
                    : <>
                        <Button
                            className="bn-center bn-primary  min-w-36"
                            icon={<TbCheck />}
                            onClick={() => handleChoosed()}
                        >ยืนยันการเลือก</Button>                    
                      </>
                }

            </Flex>
          </Col>
        </Row> 
    </>);
    return (
        <>
        <div className='modal-suppliers' id="modal-area">
            <Modal
                open={openModal}
                title={modalTitle}
                afterClose={() => handleClose() }
                onCancel={() => setOpenModal(false) } 
                maskClosable={false}
                style={{ top: 0, height: 'calc(100vh - 10px)' }}
                width={900}
                className='modal-loading-type mymodal'
                footer={modalFooter}
            >
            {/* <Spin spinning={loading} > */}
                <div style={containerStyle}> 
                    <Space direction="vertical" size="middle" style={{ display: 'flex', position: 'relative'}} className='current h-full'  >
                        <Card style={{backgroundColor:'#f0f0f0' }}>
                            <Form form={form} layout="vertical" autoComplete="off" onValuesChange={handleCriteria}  >
                                {modalSearch}
                            </Form>
                        </Card>
                        <Card style={{height:'100%'}}>
                            <TableSearchValue
                                context={context}
                                title='รายการข้อมูลรถยนต์'
                                pagingDefault={tbparamsDefault?.pagination}
                                onPageChange={(page)=>setTbparams(page)}
                                onSelectedRow={handleRowSelect}
                                onCreate={!!cus && handleCreatData}
                                onUpdate={!!cus && handleUpdateData}
                                tbProps={{
                                    rowKey:"id",
                                    dataSource:dataSource,
                                    columns:myModalColumn(),
                                    pagination: pageValue,
                                    loading
                                }}
                            />
                        </Card>
                    </Space>      
                    { (openFormManage && !!cus) &&
                        <MyFormManage 
                            form={formManage}
                            show={openFormManage} 
                            close={()=>{
                                setOpenFormManage(false);
                                formManage.resetFields();
                            }} 
                            source={dataSelect}
                            title={manageTitle}
                        />
                    }
                </div>

            {/* </Spin> */}
            </Modal>                
        </div>

        </>
    )
}

