import { Typography } from "antd";
import { comma } from "../../../utils/util";


const cellNoEdit = (clickCell) => ({
  onCell: (record, index) => ({
    style: { cursor:'pointer'},
    className: `field-edit ${!!record?.sub_item ? 'no-expend !bg-gray-200' : ''}`,
    onClick:()=>clickCell(record, index)
  })
});

export const columnDetail = (clickCell, options, rowSelect) => [
    {
      title: "ลำดับ",
      dataIndex: "ind",
      key: "ind",
      width: 80, 
      ...cellNoEdit(clickCell),
      render: rowSelect,
    },
    {
      title: "วิธีการชำระ",
      dataIndex: "payment",
      key: "payment", 
      align: "left",
      width: "40%",
      editable: true,
      type:'select',
      options,
      childProps: { showSearch: true },
      render: (v) => {
        const txt = options?.find( f  => f.value === v )?.label;
        return !!txt ? txt : <Typography.Text className="m-0 text-gray-300 font-normal">เลือกข้อมูล</Typography.Text>
      },
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "money",
      key: "money", 
      width: "20%",
      align: "right",
      className: "!pe-4",
      editable: true,
      type:'number',
      render: (_, rec) => <>{ comma( Number(rec?.money ||  0), 2, 2 )}</>,
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark", 
      align: "left", 
      editable: true,
    },
];
 

export const columnsDetailsEditable = ( handleSave, column ) => {
    const cols = column;
    const cmm  =  cols.map((col, ind) => { 
      if (!col.editable) return col;  

      return {
        ...col,
        onCell: (record) => {
          // console.log(record);
          return {
            record,
            editable: col.editable && !record?.sub_item,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
            fieldType: !!col?.textArea,
            required: !!col?.required,
            type: col?.type || "input",
            className: !!record?.sub_item ? 'field-edit !bg-gray-200 !pe-4 no-expend' : '',
            optionsItems: col.options,
            childProps: col?.childProps || {},
          };
        },
      };
  
    });
    // console.dir( cmm );
    return cmm;
  };