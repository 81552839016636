// import { useState } from 'react';
import { PROJECT_KEY } from "../../constant/constant";
import {decode as dcode, encode as ecode} from 'base-64';
const useStorage = (key = undefined) => { 

  const updateStorage = (criteria) => {
    if( !key ) return;

    // console.log( criteria );
    // console.log( JSON.stringify( criteria ) );
    const encoder = new TextEncoder();
    const utf8Array = encoder.encode(JSON.stringify( criteria ));

    let binaryStr = '';
    for (let byte of utf8Array) {
        binaryStr += String.fromCharCode(byte);
    }
    // sessionStorage.setItem(`${PROJECT_KEY}_${key}_cri`, ecode(JSON.stringify( criteria )));
    localStorage.setItem(`${PROJECT_KEY}_${key}_cri`, ecode(binaryStr));
  };

  const gettingStorage = (autoClear = true) => { 
    const value = localStorage.getItem(`${PROJECT_KEY}_${key}_cri`);
    if( !value ) return undefined;

    const binaryStr = dcode(value); 
    // Convert the binary string to a Uint8Array
    const utf8Array = new Uint8Array(binaryStr.length);
    for (let i = 0; i < binaryStr.length; i++) {
        utf8Array[i] = binaryStr.charCodeAt(i);
    }

    if( !!autoClear ) deleteStorage();
    // Decode the UTF-8 byte array to a string
    
    const decoder = new TextDecoder();
    return JSON.parse( decoder.decode(utf8Array) );
  }; 

  const deleteStorage = () => { 
    localStorage.removeItem(`${PROJECT_KEY}_${key}_cri`);
  }
  return { 
    updateStorage,
    gettingStorage, 
    deleteStorage,
  };
};

export default useStorage;