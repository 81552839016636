import { ExclamationCircleFilled } from '@ant-design/icons';
import { notification } from 'antd';

const useNotify = (config = {}) => { 

  const noti = (content=null, options={}) => {
    // return new Promise( (resolve, reject) => { 
    //   try {
        notification.open({
          message: 'แจ้งเตือนจากระบบ',
          description:content || "มีการแจ้งเตือนจากระบบ",
          onClick: () => {
            // resolve(true);
          }, 
          icon: ( <ExclamationCircleFilled style={{color: '#108ee9',}} /> ),
          // placement: 'topRight',
          // maxCount: 3,
          // duration: 3,
          // stack: false,
          // showProgress: true,
          // pauseOnHover: true,
          ...options
        });
    //   } catch (err) {
    //       console.warn( err )
    //       reject( err );
    //   }
    // }); 
  };


  return {
    noti,
  }
};

export default useNotify;