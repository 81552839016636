import { Card, Descriptions, Modal, Space } from 'antd'
import React from 'react'
import { 
  infoItem, 
  // infoItemNotQt, 
  myModalStockLocationColumn, 
  myModalStockLocationColumnEditable 
} from './model.module'
import { TableSearchValue } from '../../form';
import { ComponentEdit } from '../../table/TableEditable';
export default function MyModalItemAmount({list, master, change, hasQt}) {

  const [formValue, setFormValue] = React.useState(master);
  const [listSouce, setListSouce] = React.useState(list);
  const handleSave = (row) => {
    const newData =  (r) => {
      const itemDetail = [...list];
      const newData = [...itemDetail];
  
      const ind = newData.findIndex((item) => r?.key_code === item?.key_code);
      if (ind < 0) return itemDetail;
      const item = newData[ind];
      const amount = Number( formValue?.amount || 0);
      const qty = Number( r?.qty || 0);
      const remain = Number( r?.location_qty || 0) - qty;

      if( remain < 0) {
        Modal.error( { content: "จำนวนที่กรอกมากกว่าจำนวนสต๊อกที่มีอยู่" } );
        return itemDetail;
      }

      if( amount < qty ) {
        Modal.error( { content: "จำนวนที่กรอกมากกว่าจำนวนที่ต้องใช้" } );
        return itemDetail;
      }

      newData.splice(ind, 1, { ...item, ...r, remain});  
      const remainTotal = newData?.reduce( (a, v) => a += Number( v?.remain || 0 ), 0 );
      setFormValue(state => ({
          ...state,
          qty,
          remain: remainTotal, 
      }));
      
      return newData;
    };
    const data = [...newData(row)];
    typeof change === "function" && change( data );
    setListSouce(data);
    // console.log( data );
  };
  const information = (<> 
    {/* <Descriptions items={ hasQt ? infoItem(formValue) : infoItemNotQt(formValue)  } bordered size='small' column={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}/>  */}
    <Descriptions items={ infoItem(formValue) } bordered size='small' column={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }} /> 
  </>);

  React.useEffect( () =>{  
    // console.log("กรอกจำนวนสินค้า");
    const initeial = () => {
      const qty = listSouce.reduce( (a,v) => a += Number( v?.qty || 0 ), 0);
      setFormValue( s => ({...s, qty}));
    }
  
    initeial();
  } , [listSouce]);

  const list_stock = (<>
    <Card style={{minHeight:390}}>
        <TableSearchValue
          title='รายการสต๊อกสินค้า' 
          notSelect={true}
          provider={false}
          tbProps={{
            components:ComponentEdit,
            rowKey:"key_code",
            dataSource:listSouce,
            columns:myModalStockLocationColumnEditable(handleSave, myModalStockLocationColumn()),
          }}
        />
    </Card>
  </>);

  return <> 
  <Space direction='vertical' className='w-full'>
      {information}
      {list_stock}
  </Space>
  </> 
}
