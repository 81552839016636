import dayjs from "dayjs"; 
import { comma } from "../../../utils/util";
export const myModalColumn = () => [
    {
      title: "ลำดับ",
      dataIndex: "ind",
      key: "ind",
      width: 80,  
      render: (im, rc, index) => <>{index + 1}</>,
    },
    {
      title: "เลขที่ใบรายการซ่อม",
      dataIndex: "rpocode",
      key: "rpocode",
      width: '20%',   
    },
    {
      title: "เลขที่ใบกำกับภาษี",
      dataIndex: "invcode",
      key: "invcode",
      width: '20%',  
      render: (im) => <>{im || '-'}</>,
    },
    {
      title: "ทะเบียนรถ",
      dataIndex: "car_no",
      key: "car_no",
      width: '20%',  
      render: (_,  im) => !!im.car_no ? <>{ im.car_no } { im.car_province }</> : '-',
    },
    {
      title: "วันที่ใบรายการซ่อม",
      dataIndex: "rpodate",
      key: "rpodate",
      width: '20%',  
      render: (v) => dayjs(v).format("DD/MM/YYYY"),
    },
    {
      title: "รวมเงิน",
      dataIndex: "price_total",
      key: "price_total",
      align: 'right', 
      className: '!pe-4',
      render: (v) => comma( v, 2, 2),
    },
  ]