
export const myModalColumn = () => [
    {
        title: "ทะเบียนรถ",
        dataIndex: "car_no",
        key: "car_no",
        width: 150, 
        align: "left",
        sorter: true, 
        render: (_, r) => `${r?.car_no} ${r?.car_province}`
    },
    {
        title: "ยี่ห้อ",
        dataIndex: "car_brand",
        key: "car_brand", 
        align: "left",
        sorter: true, 
        style: { minWidth: 150 }
    },
    {
        title: "รุ่น",
        dataIndex: "car_modelname",
        key: "car_modelname", 
        width: "18%",
        align: "left",
        sorter: true,
    },
    {
        title: "รหัสลูกค้า",
        dataIndex: "cuscode",
        key: "cuscode", 
        width: "18%",
        align: "left",
        sorter: true,
    },
    {
        title: "ชื่อลูกค้า",
        dataIndex: "cusname",
        key: "cusname",  
        align: "left",
        sorter: true,
    },
];
 