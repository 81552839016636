import { Button } from 'antd'
import React from 'react'
import MyDrawerView from './MyDrawerView'
import { TbReceiptDollar } from 'react-icons/tb';

export default function MyDrawerButtonView({ value, close, selects={}, text="ดูรายละเอียด", disabled=false }) {
    const [show, setShow] = React.useState( false );
    
    const hancleOpen = () => {
        // console.log( 111  )
        setShow(true);
    }
    
    const hancleConfirmed = (v) => { 
        if( typeof value === 'function'){
            value( v ); 
        }
    }

    return (
        <div id="btn-billing-payment-view" > 
            <Button 
                icon={<TbReceiptDollar style={{fontSize:'1.4rem'}}/>} 
                className='bn-center bn-info-outline'
                onClick={() => hancleOpen()}
                disabled={disabled}
            >{text}</Button> 

            { !!show && <MyDrawerView 
                show={show} 
                close={(v)=> {
                    setShow(false);
                    if( typeof close === "function") close(v);
                }} 
                value={hancleConfirmed} 
                source={selects} 
                // getContainer={()=>document.getElementById("btn-billing-payment")} 
            /> }
        </div>
    )
}
