import axios from "../components/layout/AxiosInterceptor";
// import { BACKEND_URL } from "../utils/util";
// import {  message } from 'antd';
// import { Authenticate } from "./Authenticate.service"; 
// const [messageApi] = message.useMessage();
// const auThen = Authenticate();
export const requestService = axios;

export const getParmeter = (p) => { 
    // console.log(  p );
    return ( Object.keys(p).filter( f => !!p[f]).map( n => `${n}=${p[n]}`) ).join("&") 

};

export default requestService;