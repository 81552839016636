import { ExclamationCircleFilled, WarningFilled } from '@ant-design/icons';
import { Modal } from 'antd';

const useConfirm = () => {

  const confirm = ( options={}) => {
    return new Promise( (resolve, reject) => { 
        try {
            Modal.confirm({
                title: 'ยืนยันการทำรายการ',
                content: 'คุณต้องการทำรายการหรือไม่',
                icon: <ExclamationCircleFilled className='!text-blue-600 me-1' style={{fontSize: '1.4rem'}} />,
                okText: 'ยืนยัน',
                okType: 'primary',
                cancelText: 'ไม่',
                onOk: () => resolve(true),
                onCancel: () => resolve(false),
                ...options,
            });
        } catch (err) {
            console.warn( err )
            reject( err );
        }
    }); 
  };
  
  const saved = (options={}) => {
    return new Promise( (resolve, reject) => { 
        try {
            Modal.confirm({
                title:'ยืนยันการบันทึกข้อมูล',
                content:'คุณต้องการบันทึกรายการหรือไม่',
                icon: <ExclamationCircleFilled className='!text-green-600 me-1' style={{fontSize: '1.4rem'}} />,
                okText: 'ยืนยัน',
                okType: 'primary',
                cancelText: 'ไม่',
                onOk: () => resolve(true),
                onCancel: () => resolve(false),
                ...options,
            });
        } catch (err) {
            console.warn( err )
            reject( err );
        }  
    }); 
    
  };

  const deleted = (options={}) => {
    return new Promise( (resolve, reject) => { 
        try {
            Modal.confirm({
                title:'ยืนยันการลบข้อมูลข้อมูล',
                content:'คุณต้องการลบรายการหรือไม่',
                icon: <ExclamationCircleFilled className='text-red-500 me-1' style={{fontSize: '1.4rem'}} />,
                okText: 'ยืนยัน',
                okType: 'danger',
                cancelText: 'ไม่',
                onOk: () => resolve(true),
                onCancel: () => resolve(false),
                ...options,
            });
        } catch (err) {
            console.warn( err )
            reject( err );
        } 
    }); 
  };

  const warninged = (options={}) => {
    return new Promise( (resolve, reject) => { 
        try {
            Modal.confirm({
                title:'ยืนยันการทำรายการ',
                content:'คุณต้องการทำรายการต่อหรือไม่',
                icon: <WarningFilled className='text-orange-500 me-1' style={{fontSize: '1.4rem'}} />,
                okText: 'ยืนยัน',
                okType: undefined,
                cancelText: 'ไม่',
                okButtonProps: { className:'!bg-orange-500' },
                onOk: () => resolve(true),
                onCancel: () => resolve(false),
                ...options,
            });
        } catch (err) {
            console.warn( err )
            reject( err );
        } 
    }); 
  };

  const success = (content = null, options={}) => {
    return new Promise( (resolve, reject) => {
      try {      
        Modal.success({
          title:'ทำรายการเสร็จสิ้น',
          content: content || 'การทำงานสำเร็จ!',
          onOk(){ resolve(true) },
          ...options,
        });
      } catch (err) {
        console.warn( err )
        reject( err );
      }     
    }); 
  };

  const error = (content = null, options={}) => {
    return new Promise( (resolve, reject) => { 
      try {
        Modal.error({
          title:'เกิดข้อผิดพลาด!',
          content: content || 'การทำรายการไม่สำเร็จ',
          onOk(){ resolve(true) },
          ...options,
        });
      } catch (err) {
          console.warn( err )
          reject( err );
      } 
    }); 
  };

  const warn = (content = null, options={}) => {
    return new Promise( (resolve, reject) => { 
        try {
          Modal.warning({
            title:'แจ้งเตือน!',
            content: content || 'โปรดตรวจสอบการทำรายการ',
            onOk(){ resolve(true) },
            ...options,
          });
        } catch (err) {
            console.warn( err )
            reject( err );
        } 
      }); 
  };



  return {
    confirm,
    saved,
    deleted,
    warninged,
    success,
    error,
    warn,
  }
};

export default useConfirm;