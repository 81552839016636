import dayjs from 'dayjs';
import { comma } from '../../utils/util';
import { Typography } from 'antd';

const calTotalDiscount = (rec) => {
  const total =  Number(rec?.amount ||  0) * Number(rec?.price ||  0);
  // const discount = 1 - ( Number(rec?.discount ||  0) / 100 );
  // return total * discount;
  const discount = Number(rec?.discount ||  0);

  return total - discount;
}

const cellNoEdit = (clickCell) => ({
  onCell: (recoed, index) => ({
    className: 'field-edit',
    onClick:()=> !!clickCell && clickCell(recoed, index)
  })
})

export const accessColumn = () => [
  {        
    title: "เลขใบเสนอราคา",
    dataIndex: "qtcode",
    key: "qtcode",
    width: '', 
    align: "left",
    sorter: true, 
  },
  {
    title: "วันที่ใบเสนอราคา",
    dataIndex: "qtdate",
    key: "qtdate",
    width: '15%',
    align: "left",
    sorter: true,
    hide: false,
    render: (v) => dayjs(v).format("DD/MM/YYYY"),
  },
  {
    title: "วันที่ยืนยันราคา",
    dataIndex: "confirm_date",
    key: "confirm_date",
    width: '15%',
    align: "left",
    sorter: true,
    hide: false,
    render: (v) => dayjs(v).format("DD/MM/YYYY"),
  },
  {
    title: "รหัสลูกค้า",
    dataIndex: "cuscode",
    key: "cuscode",
    width: '15%', 
    align: "left",
    sorter: true,
    hide: false,
  },
  {
    title: "ชื่อลูกค้า",
    dataIndex: "cusname",
    key: "cusname", 
    align: "left",
    sorter: true,  
  },
]; 

export const columnDetail = (clickCell) => [
    {
      title: "ลำดับ",
      dataIndex: "ind",
      key: "ind",
      width: 80, 
      ...cellNoEdit(clickCell),
      render: (im, rc, index) => <>{index + 1}</>,
    },
    {
      title: "รหัสสินค้า",
      dataIndex: "stcode",
      key: "stcode",
      width: 120, 
      align: "left",
      ...cellNoEdit(clickCell),
    },
    {
      title: "ชื่อสินค้า",
      dataIndex: "stname",
      key: "stname", 
      align: "left", 
      ...cellNoEdit(clickCell),
    },
    {
      title: "ชื่อสินค้าสำหรับสั่งซื้อ",
      dataIndex: "qtdetail",
      key: "qtdetail", 
      align: "left",
      width: "20%",
      editable: true,
      render: (_, rec) => rec?.qtdetail || <>{rec.stname}{!!rec.material_code && `/${rec.material_code}`}</>,
    },
    {
      title: "จำนวน",
      dataIndex: "amount",
      key: "amount", 
      width: "10%",
      align: "right",
      className: "!pe-3",
      editable: true,
      type:'number',
      render: (_, rec) => <>{ comma( Number(rec?.amount ||  0),  2, 2 )}</>,
    },
    {
      title: "ราคา",
      dataIndex: "price",
      key: "price", 
      width: "10%",
      align: "right",
      className: "!pe-3",
      editable: true,
      type:'number',
      render: (_, rec) => <>{ comma( Number(rec?.price ||  0),  2, 2 )}</>,
    },
    {
      title: "ส่วนลด",
      dataIndex: "discount",
      key: "discount",
      width: "10%",
      align: "right",
      className: "!pe-3",
      editable: true,
      type:'number',
      render: (_, rec) => <>{ comma( Number(rec?.discount ||  0),  2, 2 )}</>,
    },
    {
      title: "ราคารวม",
      dataIndex: "total",
      key: "total",
      width: "10%",
      align: "right",
      className: "!pe-3",
      ...cellNoEdit(clickCell),
      render: (_, rec) => <>{ comma( calTotalDiscount(rec),  2, 2 )}</>,
    }
];

export const columnsDetailsEditable = ( handleSave, column ) => {
  const cols = column;
  const cmm  =  cols.map((col, ind) => { 
    if (!col.editable) return col; 
    return {
      ...col,
      onCell: (record) => {
        // console.log(record);
        return {
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
          fieldType: !!col?.textArea,
          required: !!col?.required,
          type: col?.type || "input",
          // autocompleteOption: col?.autocompleteOption,
        };
      },
    };

  });
  // console.dir( cmm );
  return cmm;
};

export const formData = {
  head: { 
    qtcode: null,
    qtdate: dayjs(),
    confirm_date: null,
    cuscode: null,  
    cusname: null,  
    payment_cond: null,
    address: null,
    contact_name: null,
    contact_tel: null,
    contact_email: null,
    taxnumber: null,
    isvat: true,
    vat:0.07,
    vat_percent: 0,
    discount: 0,
    price_total: 0,
    grand_total: 0,
    rpocode: null,
    pocode: null,
  },
  list: []
}

export const infoItem = (val) => [
  {
    key: 'qtcode',
    label: 'เลขใบเสนอราคา',
    children: <Typography.Text>{val?.qtcode}</Typography.Text>,
  },
  {
    key: 'qtdate',
    label: 'วันที่เสนอราคา',
    children: <Typography.Text>{dayjs(val?.qtdate).format('YYYY/MM/DD')}</Typography.Text>,
  },
  {
    key: 'confirm_date',
    label: 'วันที่ยืนยันราคา',
    children: <Typography.Text>{dayjs(val?.confirm_date).format('YYYY/MM/DD')}</Typography.Text>,
  },
  {
    key: 'payment_cond',
    label: 'เงือนไขการชำระเงิน',
    children: <Typography.Text>{val?.payment_cond}</Typography.Text>,
  },
  {
    key: 'rpocode',
    label: 'เลขใบรายการซ่อม',
    children: <Typography.Text>{val?.rpocode || '-'}</Typography.Text>,
  },
  // {
  //   key: 'isvat',
  //   label: 'ภาษีมูลค่าเพิ่ม (Vat)',
  //   children: <Typography.Text>{!!Number(val?.isvat) > 0 ? "7%" : "0%"}</Typography.Text>,
  // },
];

export const columnViewDetail = (clickCell = undefined) => [
  {
    title: "ลำดับ",
    dataIndex: "ind",
    key: "ind",
    width: 80, 
    ...cellNoEdit(clickCell),
    render: (im, rc, index) => <>{index + 1}</>,
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "stcode",
    key: "stcode",
    width: 120,
    align: "left",
    ...cellNoEdit(clickCell),
  },
  {
    title: "ชื่อสินค้า",
    dataIndex: "stname",
    key: "stname", 
    align: "left", 
    ...cellNoEdit(clickCell),
  },
  {
    title: "ชื่อสินค้าสำหรับสั่งซื้อ",
    dataIndex: "qtdetail",
    key: "qtdetail", 
    align: "left",
    width: "20%",
    ...cellNoEdit(clickCell),
  },
  {
    title: "จำนวน",
    dataIndex: "amount",
    key: "amount", 
    width: "10%",
    align: "right",
    className: "!pe-3",
    ...cellNoEdit(clickCell),
    render: (v) => <>{ comma( Number( v || 0),  2, 2 )}</>,
  },
  {
    title: "ราคาชื้อ",
    dataIndex: "price",
    key: "price", 
    width: "10%",
    align: "right",
    className: "!pe-3",
    ...cellNoEdit(clickCell),
    render: (v) => <>{ comma( Number( v || 0),  2, 2 )}</>,
  },
  {
    title: "ส่วนลด",
    dataIndex: "discount",
    key: "discount",
    width: "10%",
    align: "right",
    className: "!pe-3",
    ...cellNoEdit(clickCell),
    render: (v) => <>{ comma( Number( v || 0),  2, 2 )}</>,
  },
  {
    title: "ราคารวม",
    dataIndex: "total",
    key: "total",
    width: "10%",
    align: "right",
    className: "!pe-3",
    ...cellNoEdit(clickCell),
    render: (_, rec) => <>{ comma( calTotalDiscount(rec),  2, 2 )}</>,
  }
];

//{xs:1, sm:2, md:2, lg:3, xl:4, xxl:4} 
export const cusItem = (val) => [
  {
    key: 'cuscode',
    label: 'รหัสลูกค้า',
    children: <Typography.Text>{val?.cuscode || '\u00A0'}</Typography.Text>,
    span: { xl: 1, xxl: 1 }
    // labelStyle:lableDesStyle
  },
  {
    key: 'cusname',
    label: 'ชื่อลูกค้า', 
    children: <Typography.Text>{val?.cusname || '\u00A0'}</Typography.Text>, 
    span: { xl: 3, xxl: 3 }
    // labelStyle:lableDesStyle
  },
  {
    key: 'taxnumber',
    label: 'เลขผู้เสียภาษี', 
    children: <Typography.Text>{val?.taxnumber || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_name',
    label: 'ติดต่อ', 
    children: <Typography.Text>{val?.contact_name || '\u00A0'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_tel',
    label: 'เบอร์โทร', 
    children: <Typography.Text>{val?.contact_tel || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_email',
    label: 'อีเมล', 
    children: <Typography.Text>{val?.contact_email || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'address',
    label: 'ที่อยู่ลูกค้า', 
    children: <Typography.Text className='text-wrap'>{val?.address || '\u00A0'}</Typography.Text>,
  },
];
 

export const paycredit = [ "เงินสด","30 วัน","45 วัน","60 วัน","90 วัน","120 วัน" ];