import dayjs from 'dayjs';
import { comma } from '../../utils/util';
import { TagsMulti } from '../../components/badge-and-tag';
import { TbTagStarred } from 'react-icons/tb';
import { Typography } from 'antd';

import {TagApproveStatus} from '../../components/badge-and-tag';
import { PoStatus } from '../../components/badge-and-tag/purchase-order';

const calTotalDiscount = (rec) => {
  const total =  Number(rec?.qty ||  0) * Number(rec?.price ||  0);
  // const discount = 1 - ( Number(rec?.discount ||  0) / 100 );
  // return total * discount;
  const discount = Number(rec?.discount ||  0);

  return total - discount;
}

export const accessColumn = () => [
  {        
      title: "เลขใบสั่งซื้อ",
      dataIndex: "purcode",
      key: "purcode",
      width: 140, 
      align: "left",
      sorter: true, 
  },
  {
    title: "วันที่สั่งซื้อ",
    dataIndex: "purdate",
    key: "purdate",
    width: 160,
    align: "left",
    sorter: true,
    hide: false,
    render: (v) => dayjs(v).format("DD/MM/YYYY"),
  },
  {
    title: "วันที่ส่งของ",
    dataIndex: "duedate",
    key: "duedate",
    width: 160,
    align: "left",
    sorter: true,
    hide: false,
    render: (v) => dayjs(v).format("DD/MM/YYYY"),
  },
  {
      title: "รหัสผู้ขาย",
      dataIndex: "supcode",
      key: "supcode",
      width: 140, 
      align: "left",
      sorter: true,
      hide: false,
  },
  {
      title: "ชื่อผู้ขาย",
      dataIndex: "supname",
      key: "supname", 
      align: "left",
      sorter: true,  
  },
  {
      title: "ติดต่อ",
      dataIndex: "contact",
      key: "contact", 
      width: "22%",
      align: "left",
      sorter: true,
      hide: true,
  },
  {
      title: "สถานะ",
      dataIndex: "status",
      key: "status", 
      width: 140,
      align: "center",
      sorter: true,
      hide: false,
      render:(v)=> <PoStatus data={v} />
  },
  {
      title: "สถานะการอนุมัติ",
      dataIndex: "approved_status",
      key: "approved_status", 
      width: 140,
      align: "center",
      sorter: true,
      hide: false,
      render:(v)=> <TagApproveStatus data={v} />
  },
  {
      title: "ประเภทรายการซื้อ",
      dataIndex: "suptags",
      key: "suptags", 
      width: "18%",
      align: "left",
      sorter: true,
      hide: false,
      render: (data) => <TagsMulti data={JSON.parse(data || "[]")} icon={<TbTagStarred />}  />
  },
]; 

export const columnDetail = (clickCell) => [
    {
      title: "ลำดับ",
      dataIndex: "ind",
      key: "ind",
      width: 80, 
      onCell: (recoed, index) => ({
        onClick:()=>clickCell(recoed, index)
      }),
      render: (im, rc, index) => <>{index + 1}</>,
    },
    {
      title: "รหัสสินค้า",
      dataIndex: "stcode",
      key: "stcode",
      width: 120, 
      onCell: (recoed, index) => ({
        onClick:()=>clickCell(recoed, index)
      }),
      align: "left",
    },
    {
      title: "ชื่อสินค้า",
      dataIndex: "purdetail",
      key: "purdetail", 
      align: "left", 
      onCell: (recoed, index) => ({
        onClick:()=>clickCell(recoed, index)
      }),
      render: (_, rec) => rec.stname,
    },
    {
      title: "ชื่อสินค้าสำหรับสั่งซื้อ",
      dataIndex: "purdetail",
      key: "purdetail", 
      align: "left",
      width: "20%",
      editable: true,
      render: (_, rec) => rec?.purdetail || <>{rec.stname}{!!rec.material_code && `/${rec.material_code}`}</>,
    },
    {
      title: "จำนวน",
      dataIndex: "qty",
      key: "qty", 
      width: "10%",
      align: "right",
      className: "!pe-3",
      editable: true,
      type:'number',
      render: (_, rec) => <>{ comma( Number(rec?.qty ||  0),  2, 2 )}</>,
    },
    {
      title: "ราคาชื้อ",
      dataIndex: "price",
      key: "price", 
      width: "10%",
      align: "right",
      className: "!pe-3",
      editable: true,
      type:'number',
      render: (_, rec) => <>{ comma( Number(rec?.price ||  0),  2, 2 )}</>,
    },
    {
      title: "ส่วนลด",
      dataIndex: "discount",
      key: "discount",
      width: "10%",
      align: "right",
      className: "!pe-3",
      editable: true,
      type:'number',
      render: (_, rec) => <>{ comma( Number(rec?.discount ||  0),  2, 2 )}</>,
    },
    {
      title: "ราคารวม",
      dataIndex: "total",
      key: "total",
      width: "10%",
      align: "right",
      className: "!pe-3",
      onCell: (record, index) => ({
        onClick:()=>clickCell(record, index)
      }),
      render: (_, rec) => <>{ comma( calTotalDiscount(rec),  2, 2 )}</>,
    }
];

export const columnsDetailsEditable = ( handleSave, column ) => {
  const cols = column;
  const cmm  =  cols.map((col, ind) => { 
    if (!col.editable) return col; 
    return {
      ...col,
      onCell: (record) => {
        // console.log(record);
        return {
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
          fieldType: !!col?.textArea,
          required: !!col?.required,
          type: col?.type || "input",
          // autocompleteOption: col?.autocompleteOption,
        };
      },
    };

  });
  // console.dir( cmm );
  return cmm;
};

export const formData = {
  head: { 
    purcode: null,
    supcode: null,
    supname: null,
    purdate: dayjs(),
    duedate: null,
    qtcode: null,
    payment: null,
    address: null,
    created_date: null,
    updated_date: null,
    created_by: null,
    updated_by: null,
    contact: null,
    taxnumber: null,
    total: 0,
    vat:0.07,
    isvat: true,
    grand_total: 0,
    approved_status: null,
  },
  list: []
}


export const infoItem = (val) => [
  {
    key: 'prudate',
    label: 'วันที่สั่งซื้อ',
    children: <Typography.Text>{dayjs(val?.purdate).format('YYYY/MM/DD')}</Typography.Text>,
  },
  {
    key: 'durdate',
    label: 'วันที่นัดส่งของ',
    children: <Typography.Text>{dayjs(val?.durdate).format('YYYY/MM/DD')}</Typography.Text>,
  },
  {
    key: 'payment',
    label: 'การชำระเงิน/เครดิตเทอม',
    children: <Typography.Text>{val?.payment}</Typography.Text>,
  },
  {
    key: 'qtcode',
    label: 'เลขใบเสนอราคา',
    children: <Typography.Text>{val?.qtcode || '-'}</Typography.Text>,
  },
  {
    key: 'isvat',
    label: 'ภาษีมูลค่าเพิ่ม (Vat)',
    children: <Typography.Text>{Number(val?.vat) > 0 ? "7%" : "0%"}</Typography.Text>,
  },
];

//{xs:1, sm:2, md:2, lg:3, xl:4, xxl:4} 
export const supItem = (val) => [
  {
    key: 'supcode',
    label: 'รหัสผู้ขาย',
    children: <Typography.Text>{val?.supcode || '\u00A0'}</Typography.Text>,
    span: { xl: 1, xxl: 1 }
    // labelStyle:lableDesStyle
  },
  {
    key: 'supname',
    label: 'ชื่อผู้ขาย', 
    children: <Typography.Text>{val?.supname || '\u00A0'}</Typography.Text>, 
    span: { xl: 3, xxl: 3 }
    // labelStyle:lableDesStyle
  },
  {
    key: 'taxnumber',
    label: 'เลขผู้เสียภาษี', 
    children: <Typography.Text>{val?.taxnumber || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_name',
    label: 'ติดต่อ', 
    children: <Typography.Text>{val?.contact_name || '\u00A0'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_tel',
    label: 'เบอร์โทร', 
    children: <Typography.Text>{val?.contact_tel || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_email',
    label: 'อีเมล', 
    children: <Typography.Text>{val?.contact_email || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'address',
    label: 'ที่อยู่ผู้ขาย', 
    children: <Typography.Text className='text-wrap'>{val?.address || '\u00A0'}</Typography.Text>,
  },
];

export const appvItem = (val) => [
  {
    key: 'approved_date',
    label: 'วันที่อนุมัติ',
    children: <Typography.Text>{dayjs(val?.approved_date).format('YYYY/MM/DD HH:mm:ss')}</Typography.Text>,
    span: { xl: 1, xxl: 1 }
  },
  {
    key: 'approved_status',
    label: 'ผลการอนุมัติ',
    children: <TagApproveStatus data={val.approved_status} />,  
  },
  {
    key: 'none-1',
    label: false,
    children: '\u00A0',  
  },
  {
    key: 'none-2',
    label: false,
    children: '\u00A0', 
  },
  {
    key: 'approved_comment',
    label: 'ความคิดเห็น',
    children: <pre className='border-0 italic' style={{fontSize: 'clamp(11px, 0.7vw, 13px)',padding: '8px 10px'}} >{val?.approved_comment}</pre>,
  }, 
];

export const paycredit = [ "เงินสด","30 วัน","45 วัน","60 วัน","90 วัน","120 วัน" ];