import dayjs from 'dayjs';
import { comma } from '../../utils/util';
import { Typography } from 'antd';
import { PoStatus } from '../../components/badge-and-tag/purchase-order';
import TagIs from '../../components/badge-and-tag/tags-is/TagIs';

// const calRemain = (rec) => {
//   const remain =  Number(rec?.qty ||  0) - Number(rec?.received ||  0) - Number(rec?.amount ||  0);
//   return remain;
// }

const calTotalPrice = (rec) => {
  const total =  Number(rec?.amount ||  0) * Number(rec?.price ||  0);
  // const discount = 1 - ( Number(rec?.discount ||  0) / 100 );
  const discount = Number(rec?.discount ||  0);

  return total - discount;
}

const onCell = (clickCell) => ({ 
  onCell: (record, index) => ({ 
    onClick:()=>clickCell(record, index) ,
    className: "field-edit" 
  }),
});

export const accessColumn = () => [
  {        
      title: "เลขใบรับสินค้า",
      dataIndex: "grcode",
      key: "grcode",
      width: '14%', 
      align: "left",
      sorter: true, 
  },
  {
    title: "วันที่ใบรับสินค้า",
    dataIndex: "grdate",
    key: "grdate",
    width: 160,
    align: "left",
    sorter: true, 
    render: (v) => dayjs(v).format("DD/MM/YYYY"),
  },
  {        
    title: "เลขใบสั่งซื้อ",
    dataIndex: "purcode",
    key: "purcode",
    width: '14%',
    align: "left",
    sorter: true, 
  },
  {
    title: "วันที่สั่งซื้อ",
    dataIndex: "purdate",
    key: "purdate",
    width: 160,
    align: "left",
    sorter: true,
    hide: false,
    render: (v) => dayjs(v).format("DD/MM/YYYY"),
  },
  {
      title: "รหัสผู้ขาย",
      dataIndex: "supcode",
      key: "supcode",
      width: '14%', 
      align: "left",
      sorter: true,
      hide: false,
  },
  {
      title: "ชื่อผู้ขาย",
      dataIndex: "supname",
      key: "supname", 
      align: "left",
      sorter: true,
      hide: false,
  },
  {
      title: "ชื่อผู้รับ",
      dataIndex: "created_name",
      key: "created_name", 
      align: "left",
      sorter: true,
      hide: false,
  },
]; 

export const columnReceipted = () => [ 
  {        
      title: "เลขใบรับสินค้า",
      dataIndex: "grcode",
      key: "grcode",
      width: 140, 
      align: "left",
      sorter: true, 
  },
  {        
      title: "เลขใบสั่งซื้อ",
      dataIndex: "purcode",
      key: "purcode",
      width: 140, 
      align: "left",
      sorter: true, 
  }, 
  {
    title: "วันที่รับสินค้า",
    dataIndex: "grdate",
    key: "grdate",
    width: 160,
    align: "left",
    sorter: true,
    hide: false,
    render: (v) => dayjs(v).format("DD/MM/YYYY"),
  }, 
  {
    title: "จำนวนรับ",
    dataIndex: "amount",
    key: "qty", 
    width: "15%",
    align: "right",
    className: "!pe-3", 
    render: (v) => <>{comma(Number(v || 0))}</>,
  }, 
  {
    title: "ผู้รับ",
    dataIndex: "created_name",
    key: "created_name", 
  },  
];

export const columnDetail = (clickCell, options, action, onCodeVat) => [
  {
    title: "ลำดับ",
    dataIndex: "ind",
    key: "ind",
    width: 80,
    className: 'field-edit',
    ...onCell(clickCell),
    render: (im, rc, index) => <>{index + 1}</>,
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "stcode",
    key: "stcode",
    width: 120,
    className: 'field-edit', 
    ...onCell(clickCell),
    align: "left",
  },
  {
    title: "รหัส VAT",
    dataIndex: "stcode_vat_show",
    key: "stcode_vat_show",
    width: 120,
    className: 'field-edit',
    align: "left",
    render: onCodeVat
  },
  // {
  //   title: "ชื่อสินค้า",
  //   dataIndex: "stname",
  //   key: "stname", 
  //   align: "left",
  //   className: 'field-edit', 
  //   ...onCell(clickCell),
  //   render: (_, rec) => rec.stname,
  // },
  {
    title: "ชื่อสินค้า",
    dataIndex: "purdetail",
    key: "purdetail", 
    align: "left",
    className: 'field-edit',
    // width: "20%",
    ...onCell(clickCell),
    render: (_, rec) => rec?.purdetail,
  },
  {
    title: "สถานที่เก็บ",
    dataIndex: "location_code",
    key: "location_code",
    align: "left",
    width: "15%",
    editable: true,
    type:'select',
    options,
    render: (v) => {
      return options?.find( f  => f.value === v )?.label
    },
  },
  {
    title: "คลัง VAT",
    dataIndex: "isvat",
    key: "isvat",
    align: "left",
    width: "5%",
    editable: true,
    type:'switch',
    formProp: { valuePropName:"checked" },
    render: (v) => <TagIs result={Number(v)} />
  },
  {
    title: "รับแล้ว/จำนวนสั่ง",
    dataIndex: "qty",
    key: "qty", 
    width: "8%",
    align: "right",
    className: "!pe-3 field-edit",
    ...onCell(clickCell),
    render: (_, rec) => <>{comma(Number(rec?.received || 0),2,2)}/{comma(Number(rec?.qty || 0),2,2)}</>,
  },
  {
    title: "จำนวนรับ",
    dataIndex: "amount",
    key: "amount", 
    width: "8%",
    align: "right",
    // className: "!pe-3 field-edit !bg-gray-300 h-noborder",
    // onCell: (rec) => {
    //   const isamount = (Number(rec?.amount || 0) > 0 && Number(rec?.amount || 0) >= Number(rec?.qty || 0) ) ? 'text-green-700' : 'text-red-600'
    //   return {
    //     className: `!pe-3 field-edit !bg-gray-300 ${isamount}`
    //   }
    // },
    className: "",
    editable: true,
    type:'number',
    render: (_, rec) => <>{comma(Number(rec?.amount || 0),2,2)}</>,
  },
  {
    title: "ราคา",
    dataIndex: "price",
    key: "price",
    width: "8%",
    align: "right",
    className: "",
    editable: true,
    type:'number',
    // onCell: (record, index) => ({ className: (['ยางรถยนต์'].includes(record?.categoryname)) ? 'field-edit' : '!pe-3' }),
    render: (_, rec) => <>{comma(Number(rec?.price || 0),2,2)}</>,
  },
  {
    title: "ส่วนลด",
    dataIndex: "discount",
    key: "discount",
    width: "8%",
    align: "right",
    className: "!pe-3",
    editable: true,
    type:'number',
    render: (_, rec) => <>{ comma( Number(rec?.discount ||  0),2,2 )}</>,
  },
  {
    title: "ราคารวม",
    dataIndex: "total_price",
    key: "total_price",
    width: "8%",
    align: "right",
    className: "!pe-3 field-edit",
    ...onCell(clickCell),
    render: (_, rec) => <>{comma(calTotalPrice(rec),2,2)}</>,
  },
  {
    title: "ตัวเลือก",
    dataIndex: "action",
    key: "action",
    width: "8%", 
    className: "!pe-3 field-edit", 
    render: action,
  }
];

export const columnsDetailsEditable = ( handleSave, column ) => {
  const cols = column;
  const cmm  =  cols.map((col, ind) => { 

    if (!col.editable) return col; 
    return {
      ...col,
      onHeaderCell: (record) => ({ className: 'field-edit' }),
      onCell: (record) => {

        if( col.dataIndex === "amount" && Number(record.stock_by_product || 0) === 1 ){
          const isamount = (Number(record?.amount || 0) > 0 && (Number(record?.amount || 0) + Number(record?.received || 0)) >= Number(record?.qty || 0) ) ? 'text-green-700' : 'text-red-600'
          return {
            className: `field-edit !bg-gray-300 ${isamount}`,
            style: { paddingRight: '1.1rem' }
          } 
        }
        // const isTire = ['ยางรถยนต์'].includes(record?.categoryname);
        // const catEdit = !isTire && col.editable;
        // const locEdit = col.dataIndex === "location_code" && col.editable;
        let prop = {};
        if( col?.type === 'number' && col.dataIndex === 'amount' ){
          prop = { max:Number(record?.qty || 0), min: 0}; 
        }

        // if(  !!record?.disabled ) console.log( record );
        return !!record?.disabled  //isTire && !locEdit
        ? { className: 'field-edit' }
        : {
          record,
          editable: col.editable, //catEdit || locEdit,
          dataIndex: col.dataIndex,
          title: col.title,
          fieldType: !!col?.textArea,
          required: !!col?.required,
          type: col?.type || "input",
          optionsItems: col.options,
          handleSave,
          childProps: prop,
          // autocompleteOption: col?.autocompleteOption,
        };
      },
    };

  });
  // console.dir( cmm );
  return cmm;
};

export const columnDetailTires = (clickCell, options, action) => [
    {
      title: "ลำดับ",
      dataIndex: "ind",
      key: "ind",
      width: 80,
      className: 'field-edit',
      ...onCell(clickCell),
      render: (im, rc, index) => <>{index + 1}</>,
    },
    // {
    //   title: "รหัสสินค้า",
    //   dataIndex: "stcode",
    //   key: "stcode",
    //   width: 120,
    //   className: 'field-edit', 
    //   ...onCell(clickCell),
    //   align: "left",
    // },
    // {
    //   title: "สถานที่เก็บ",
    //   dataIndex: "location_code",
    //   key: "location_code",
    //   align: "left",
    //   editable: true,
    //   type:'select', 
    //   optionsItems:options,
    //   render: (v) => {
    //     return options?.find( f  => f.value === v )?.label
    //   },
    // },
    {
      title: "สัปดาห์/ปี/ไตรมาส",
      dataIndex: "wyqdate",
      key: "wyqdate", 
      className: 'field-edit', 
      ...onCell(clickCell),
      align: "left",
      render: (_, v) => <>{dayjs(v?.mfweek_date).format("w")}/{dayjs(v?.mfyear_date).format("YYYY")}/Q{dayjs(v?.quarter_date).format("Q")}</>,
    },    
    {
      title: "สัปดาห์/ผลิต",
      dataIndex: "mfweek_date",
      key: "mfweek_date", 
      width: "18%",
      align: "left",
      className: 'field-edit',
      ...onCell(clickCell),
      // editable: true,
      // type:'date',
      // required: true,
      // dateProps: {format:'w', placeholder:'สัปดาห์ที่ผลิต', picker:"week"},
      render: (v) => <>{dayjs(v).format("w")}</>,
    },
    {
      title: "ปี/ผลิต",
      dataIndex: "mfyear_date",
      key: "mfyear_date", 
      width: "18%",
      align: "left",
      className: 'field-edit',
      ...onCell(clickCell),
      // editable: true,
      // type:'date', 
      // required: true,
      // dateProps: {format:'YYYY', placeholder:'ปีที่ผลิต', picker:"year"},
      render: (v) => <>{dayjs(v).format("YYYY")}</>,
    },
    {
      title: "ไตรมาส",
      dataIndex: "quarter_date",
      key: "quarter_date",
      width: "14%",
      align: "left",
      className: 'field-edit',
      // editable: true,
      // type:'date',
      // required: true,
      // dateProps: {format:{quarterFormat}, placeholder:'ไตรมาส', picker:"quarter"},
      render: (v) => <>Q{dayjs(v).format("Q")}</>,
    },    
    {
      title: "จำนวนรับ",
      dataIndex: "amount",
      key: "amount",
      width: "14%",
      align: "right",
      className: 'field-edit !pe-3',
      editable: true,
      type:'number',
      required: true,
      // dateProps: {format:{quarterFormat}, placeholder:'ไตรมาส', picker:"quarter"},
      render: (v) => <>{comma(Number( v || 0, 2, 2))}</>,
    },    
    {
      title: "ตัวเลือก",
      dataIndex: "action",
      key: "action",
      width: "10%", 
      onHeaderCell: (record) => ({ className: 'field-edit' }),
      className: "align-middle", 
      render: action,
    }
];

export const columnsDetailTiresEditable = ( handleSave, option, {column, oncell, action} ) => {
  const cols = column(oncell, option, action);
  const cmm  =  cols.map((col, ind) => { 
    if (!col.editable) return col; 
    return {
      ...col,
      onHeaderCell: (record) => ({ className: 'field-edit' }),
      onCell: (record) => {
        // console.log(record);
        return {
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          fieldType: !!col?.textArea,
          required: !!col?.required,
          type: col?.type || "input",
          optionsItems:option,
          handleSave,
          dateProps:col.dateProps
          // autocompleteOption: col?.autocompleteOption,
        };
      },
    };

  });
  // console.dir( cmm );
  return cmm;
};

export const formData = {
  head: { 
    grcode: null,
    grdate: dayjs(),
    dncode: null,
    dndate: null,
    invcode: null,
    invdate: null,
    supcode: null,
    supname: null,
    payment: null,
    address: null,
    created_date: null,
    updated_date: null,
    created_by: null,
    updated_by: null,
    contact: null,
    taxnumber: null,
    total: 0,
    vat:0,
    grand_total: 0,
    approved_status: null,
    purcode: null,
  },
  list: []
};

export const formTiresData = { 
  stcode:null,
  stname:null,
  purdetail:null,
  qty:0,
  received:0,
  remain:0,
  mfweek:null,
  mfyear:null,
  quarter:null,
  receive_qty:0
};

export const columnPurchaseOrder = () => [
  {
    title: "ลำดับ",
    dataIndex: "ind",
    key: "ind",
    width: 80, 
    render: (im, rc, index) => <>{index + 1}</>,
  },
  {        
    title: "เลขใบสั่งซื้อ",
    dataIndex: "purcode",
    key: "purcode",
    width: '18%', 
    align: "left",
    sorter: true, 
  },
  {
    title: "วันที่สั่งซื้อ",
    dataIndex: "purdate",
    key: "purdate",
    width: '15%',
    align: "left",
    sorter: true,
    render: (v) => dayjs(v).format("DD/MM/YYYY"),
  },
  {
    title: "วันที่ส่งของ",
    dataIndex: "duedate",
    key: "duedate",
    width: '15%',
    align: "left",
    sorter: true,
    render: (v) => !!v ? dayjs(v).format("DD/MM/YYYY") : "-",
  },
  {        
    title: "เลขใบเสอราคา",
    dataIndex: "qtcode",
    key: "qtcode",
    width: '18%',
    align: "left",
    sorter: true, 
  },
  {
    title: "สถานะ",
    dataIndex: "status",
    key: "status", 
    align: "left",
    sorter: true,
    render: (v) => <PoStatus data={v} />,
  },  
];

export const columnDetailView = () => [
  {
    title: "ลำดับ",
    dataIndex: "ind",
    key: "ind",
    width: 80, 
    render: (im, rc, index) => <>{index + 1}</>,
  },
  {        
    title: "เลขใบสั่งซื้อ",
    dataIndex: "purcode",
    key: "purcode",
    width: 120,
    align: "left",
  },
  {
    title: "รหัสสินค้า",
    dataIndex: "stcode",
    key: "stcode",
    width: 120,
    align: "left",
  },
  {
    title: "สินค้า",
    dataIndex: "stname",
    key: "stname",
  },
  {
    title: "สถานที่เก็บ",
    dataIndex: "location_name",
    key: "location_name",
    width: '10%',
  },
  {
    title: "คลัง VAT",
    dataIndex: "isvat",
    key: "isvat",
    align: "center",
    width: 50,
    render: (v) => <TagIs result={Number(v)} />,  
  },
  {        
    title: "จำนวนรับ",
    dataIndex: "amount",
    key: "amount",
    width: '10%',
    align: "right",
    className: "!pe-3",
    render: (v) => <>{comma(Number(v || 0),2,2)}</>,    
  },
  {        
    title: "ราคา",
    dataIndex: "price",
    key: "price",
    width: '10%',
    align: "right",
    className: "!pe-3",
    render: (v) => <>{comma(Number(v || 0),2,2)}</>,    
  },
  {        
    title: "ส่วนลด",
    dataIndex: "discount",
    key: "discount",
    width: '10%',
    align: "right",
    className: "!pe-3",
    render: (v) => <>{comma(Number(v || 0),2,2)}</>,    
  },
  {        
    title: "ราคารวม",
    dataIndex: "total_price",
    key: "total_price",
    width: '10%',
    align: "right",
    className: "!pe-3",
    render: (_, rec) => <>{comma(calTotalPrice(rec),2,2)}</>,  
  },
];

export const infoPurchase = (val) => [
  {
    key: 'prudate',
    label: 'วันที่สั่งซื้อ',
    children: <Typography.Text>{dayjs(val?.purdate).format('YYYY/MM/DD')}</Typography.Text>,
  },
  {
    key: 'durdate',
    label: 'วันที่นัดส่งของ',
    children: <Typography.Text>{dayjs(val?.durdate).format('YYYY/MM/DD')}</Typography.Text>,
  },
  {
    key: 'payment',
    label: 'การชำระเงิน/เครดิตเทอม',
    children: <Typography.Text>{val?.payment}</Typography.Text>,
  },
  {
    key: 'qtcode',
    label: 'เลขใบเสนอราคา',
    children: <Typography.Text>{val?.qtcode || '-'}</Typography.Text>,
  },
  {
    key: 'isvat',
    label: 'ภาษีมูลค่าเพิ่ม (Vat)',
    children: <Typography.Text>{ !!Number(val?.isvat) ? "7%" : "0%"}</Typography.Text>,
  },
];

export const infoGoodsReceipt = (val) => [
  {
    key: 'grcode',
    label: 'รหัสใบรับสินค้า',
    children: <Typography.Text>{val?.grcode}</Typography.Text>,
  },
  {
    key: 'grdate',
    label: 'วันที่ใบรับสินค้า',
    children: <Typography.Text>{dayjs(val?.grdate).format('YYYY/MM/DD')}</Typography.Text>,
  },
  {
    key: 'dncode',
    label: 'รหัสใบส่งสินค้า',
    children: <Typography.Text>{val?.dncode}</Typography.Text>,
  },
  {
    key: 'dndate',
    label: 'วันที่ใบส่งสินค้า',
    children: !!val?.dndate && <Typography.Text>{dayjs(val?.dndate).format('YYYY/MM/DD')}</Typography.Text>,
  },
  {
    key: 'invcode',
    label: 'รหัสใบแจ้งหนี้',
    children: <Typography.Text>{val?.invcode}</Typography.Text>,
  },
  {
    key: 'incdate',
    label: 'วันที่ใบแจ้งหนี้',
    children: !!val?.invdate && <Typography.Text>{dayjs(val?.invdate).format('YYYY/MM/DD')}</Typography.Text>,
  },
  {
    key: 'created_name',
    label: 'ผู้รับสินค้า',
    children: <Typography.Text>{val?.created_name}</Typography.Text>,
  },
];

//{xs:1, sm:2, md:2, lg:3, xl:4, xxl:4} 
export const supItem = (val) => [
  {
    key: 'supcode',
    label: 'รหัสผู้ขาย',
    children: <Typography.Text>{val?.supcode || '\u00A0'}</Typography.Text>,
    span: { xl: 1, xxl: 1 }
    // labelStyle:lableDesStyle
  },
  {
    key: 'supname',
    label: 'ชื่อผู้ขาย', 
    children: <Typography.Text>{val?.supname || '\u00A0'}</Typography.Text>, 
    span: { xl: 3, xxl: 3 }
    // labelStyle:lableDesStyle
  },
  {
    key: 'taxnumber',
    label: 'เลขผู้เสียภาษี', 
    children: <Typography.Text>{val?.taxnumber || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_name',
    label: 'ติดต่อ', 
    children: <Typography.Text>{val?.contact_name || '\u00A0'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_tel',
    label: 'เบอร์โทร', 
    children: <Typography.Text>{val?.contact_tel || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'contact_email',
    label: 'อีเมล', 
    children: <Typography.Text>{val?.contact_email || '-'}</Typography.Text>,
    // labelStyle:lableDesStyle
  },
  {
    key: 'address',
    label: 'ที่อยู่ผู้ขาย', 
    children: <Typography.Text className='text-wrap'>{val?.address || '\u00A0'}</Typography.Text>,
  },
];

export const paycredit = [ "เงินสด","30 วัน","45 วัน","60 วัน","90 วัน","120 วัน" ];