import { Flex, message, Select, Typography } from 'antd';
import React from 'react';
import OptionService from "../../../service/Options.service";
import "../../../assets/styles/banks.css";

const optrequest = OptionService();
export default function MySelect({ name="bank", onChange, value=null, style={}, className="" }) {
  const [options, setOptions] = React.useState([]);
  React.useEffect(() =>{ 
    const init = async () => {
      try{
        const [ optionsRes ] = await Promise.all([
          optrequest.optionsBanks()
        ]);

        const {data:optionDataRes} = optionsRes.data; 
        const opnNew = optionDataRes.map( v => ({
            value: v?.key, 
            label: (
              <>
                  <Flex align='center' gap={8}>
                      <i className={`bank bank-${v.key} shadow huge`} style={{height:30, width:30}}></i>
                      <Flex align='start' gap={1} vertical>
                          {/* <Typography.Text ellipsis style={{ fontSize: 13 }}>{v.thai_name}</Typography.Text>  */}
                          <Typography.Text ellipsis={true} style={{ fontSize: 11, color:'#8c8386' }}>{v.official_name}</Typography.Text> 
                      </Flex>
                  </Flex>
              </>
            ),
            record: v, 
        }));
        setOptions(opnNew);
      } catch ( e ){
        console.log(e);
        message.error("เกิดข้อผิดพลาดจากการดึงข้อมูล");
      } 
    }

    init(); 
  }, []);

  const handleChange = (e, res) => {
    if( typeof onChange === "function") {
      onChange(e, res);
    }
  }

  return (
    <Select 
        showSearch
        autoClearSearchValue
        style={{ height:42, width:'100%' }}
        options={options} 
        optionFilterProp="children"
        filterOption={(input, option) => { 
            const { record:v } = option; 
            const val = input?.toLowerCase();
            return (
                (v?.official_name?.toLowerCase() ?? '').includes(val) || 
                (v?.thai_name?.toLowerCase() ?? '').includes(val) || 
                (v?.key?.toLowerCase() ?? '').includes(val)
            ) 
        }}
        filterSort={(optionA, optionB) => { 
            const { record:v1 } = optionA; 
            const { record:v2 } = optionB; 

            return (v1?.official_name ?? '').toLowerCase().localeCompare((v2?.official_name ?? '').toLowerCase())
        }
        } 
        optionLabelProp="label" 
        optionRender={ (option) => { 
            const { record:v } = option.data;
            return (
            <>
                <Flex align='self-end' gap={8}>
                    <i className={`bank bank-${v.key} shadow huge flex flex-grow-1`} style={{height:34, width:34, minWidth: 34}} ></i>
                    <Flex align='start' gap={1} vertical>
                        <Typography.Text ellipsis style={{ fontSize: 13, maxWidth:'100%' }}>{v.thai_name}</Typography.Text> 
                        <Typography.Text ellipsis style={{ fontSize: 11, color:'#8c8386', maxWidth:'100%'}}>{v.official_name}</Typography.Text> 
                    </Flex>
                </Flex>
            </>
        )} }
        allowClear 
        placeholder='กรุณาเลือกธนาคาร'
        onChange={handleChange}
    />   
    )
}
