import { requestService as api, getParmeter } from "./Request.service"  
const API_URL = { 
  API_MANAGE: `/goods-receipt/manage.php`, 
  API_SEARCH: `/goods-receipt/search.php`, 

  API_APPROVE: `/goods-receipt/approve.php`,
};

const GoodsReceiptService = () => { 
  
  const create = (parm = {}) => api.post(`${API_URL.API_MANAGE}`, parm);
  const update = (parm = {}) => api.put(`${API_URL.API_MANAGE}`, parm);
  const deleted = (parm = {}) => api.delete(`${API_URL.API_MANAGE}?${getParmeter(parm)}`);
  const get = (code) => api.get(`${API_URL.API_MANAGE}?code=${code}`);
 
  const search = (parm = {}, config = {}) => api.post(`${API_URL.API_SEARCH}`, parm, {...config, cancle: true});

  const approve = (parm = {}) => api.put(API_URL.API_APPROVE, parm);
  const waiting = () => api.get(`${API_URL.API_APPROVE}`, { cancle: true, ignoreLoading : true} );
  return {
    create,
    update,
    deleted,
    get,
    search,
    approve,
    waiting,
  };
};

export default GoodsReceiptService;