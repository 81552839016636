/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Modal, Card, Form, Typography, Flex, Divider, Descriptions, Row, Col, Button, message, Spin, Table } from "antd";
import { Space } from "antd";

import { carInfo, columnViewDetail, cusItem, infoVat, stockVat, vildateListForTaxInvoice } from "./model.module.js";  
import TaxInvoiceService from '../../../service/TaxInvoice.service.js'; 
import { TbCheck, TbReceipt, TbReceiptTax } from 'react-icons/tb';

import "./MyModal.css";
import { comma } from '../../../utils/util.js';
import useConfirm from '../../../store/hook/use-confirm.hook.js';
const apirequest = TaxInvoiceService();
const dividerProp = {orientation:"left", style:{marginBlock:10}, className:'!border-black'}; 
export default function MyModal({show, close, values, source}) { 
    const confirms = useConfirm();
    const [form] = Form.useForm();
    const [openModal, setOpenModal] = React.useState(show);
    const [loading,  setLoading] = React.useState(true);  
    

    const [dataHeader, setDataHeader] = React.useState({});
    const [dataList, setDataList] = React.useState([]);
    const [dataTaxs, setDataTaxs] = React.useState([]);
    const [dataListValid, setDataListValid] = React.useState(false);

    const [invType, setInvType] = React.useState(0);


    const containerStyle = {
      position: 'relative',
      overflow: 'hidden',
    };

    const handleConfirm =(val) => {
        // console.log( val );
        setLoading(true);
        try {
            const payload = { head: {...dataHeader, ...val }, list: dataList }
            apirequest.create(payload, { ignoreLoading : true }).then(  async (res) => {
                
                const result = await confirms.confirm({content:"ต้องการที่จะปริ้นท์ใบกำกับภาษีหรือไม่"});
                if( !!result ) {
                    handlePrintTaxInvoice(dataHeader.rpocode);
                }
                
                if( !!values ) values( { ...val } ); 
                handleClose();
                message.success("สร้างใบกำกับภาษีเสร็จสิ้น.");
            }).catch( e => {
                console.log( e );
                const { data } = e?.response || {};
                Modal.error({content: data?.option || 'เกิดข้อผิดพลาดในการร้องขอข้อมูล'})
            })
            
        } catch (error) {
            console.log( error );
            Modal.error({content: "เกิดข้อผิดพลาดจากการแก้ไขข้อมูล"})
        } finally {
            setLoading( false );
        }
    }

    const handleClose = () =>{ 
        setOpenModal(false); 
        setTimeout( () => { close(false);  }, 140); 
        //setTimeout( () => close(false), 200 );
    } 

    const handlePrintTaxInvoice = (code) => { 
        // const newWindow = window.open('', '_blank');
        // newWindow.location.href = `/receipt-print/${code}`;
        const url = `/receipt-print/${code}/1`;
        const newWindow = window.open('', url, url);
        newWindow.location.href = url;
    }

    const handleCancel = async (code) => {
        try { 
          const result = await confirms.confirm({content: 'คุณต้องการยกเลิกใบกำกับภาษีหรือไม่'});
          if(!!result){  
            await apirequest.deleted(code);
            if( !!values ) values();
            handleClose();
            message.success("ยกเลิกใบกำกับภาษีเรียบร้อย");
          }
        } catch ( er ){
          console.log( er ); 
          const { data:{ option } } = er.response;
          message.error(`Error request. ${option}`);
        }
    }

    const handleValuechange = ( v, f) => {
        // console.log({f})
        // setDataHeader( h => ({...h, ...f}));
    }

    const handleTypeChange = (e) => {
        const val = e.target.value;
        setInvType( Number( val ) );
    } 

    React.useEffect(() => {
        try {
            const { head, list, taxs } = source;

            if( !head?.invcode || !head?.invtype ){
                const { price_total } = head;
                const vat = Number( price_total || 0 ) * 0.07;
                const grand_total = Number( price_total || 0 ) + vat;
                const newHead = { ...head, invtype: 0, vat, grand_total };
                setDataHeader( newHead );  
                setTimeout( () => form.setFieldsValue({...newHead}), 400 );
            } else {
                setInvType( head?.invtype );
            } 

            setDataList( list );
            setDataTaxs( taxs ); 
            setDataListValid( vildateListForTaxInvoice(list) );
        } catch ( error ) {
            Modal.error({content:'เกิดข้อผิดพลาดในหารร้องขอข้อมูล'});
        } finally { 
            setLoading( false );
        }
    }, [source, form]); 

    React.useEffect(() => {
        try {
            switch( invType ){
                case 0: 
                    setDataHeader( setInvStyle1() );
                break;
                case 1: 
                    setDataHeader( setInvStyle2() );
                break;
                default: return;
            } 
        } catch ( error ) {
            Modal.error({content:'เกิดข้อผิดพลาดในหารร้องขอข้อมูล'});
        } finally { 
            setLoading( false );
        }
    }, [ invType ]); 

    const setInvStyle1 = () => {
        const { head } = source;
        const { price_total } = head;
        const vat = Number( price_total || 0 ) * 0.07;
        const grand_total = Number( price_total || 0 ) + vat;
        const newHead = { invtype: 0, ...head, vat, grand_total };
        return newHead;
    }

    const setInvStyle2 = () => {
        const { head } = source;
        const { price_total } = head;
        const vat = Number( price_total || 0 ) * 0.07;
        const grand_total = Number( price_total || 0 );
        const newHead = { invtype: 1, ...head, price_total: price_total - vat, vat, grand_total };
        return newHead;
    }

    const modalTitle = (<>
        <Flex align='center' gap={4}>
            <TbReceiptTax  style={{fontSize: '1rem'}} />
            <Typography.Text className='ms-1 mb-0'>ออกใบกำกับภาษี</Typography.Text>
        </Flex>    
    </>); 

    const information_items = (<>
        <Divider {...dividerProp}>ข้อมูลลูกค้า</Divider>
        <Descriptions title={false} layout="vertical"  size='small' column={{xs:1, sm:2, md:3, lg:4, xl:4, xxl:4}} items={cusItem(dataHeader)} />
    </>);

    const information_cars = (<>
        <Divider {...dividerProp}>ข้อมูลลูกค้า</Divider>
        <Descriptions title={false} layout="vertical"  size='small' column={{xs:1, sm:2, md:2, lg:2, xl:2, xxl:2}} items={carInfo(dataHeader)} />
    </>);

    const information_vat = (<>
        <Divider {...dividerProp}>ข้อมูลเอกสาร</Divider>
        <Descriptions title={false} layout="vertical" size='small' column={{xs:1, sm:2, md:2, lg:2, xl:2, xxl:2}} items={infoVat(dataHeader, handleTypeChange)} />
    </>);

    const information_list = (<>
        <Divider {...dividerProp}> รายการสินค้า</Divider>
        <Table 
            columns={columnViewDetail()} 
            dataSource={dataTaxs} 
            pagination={false} 
            scroll={{ x: 'max-content' }}
            size='small' rowKey="stcode" id="list-data"
            summary={ (_) => totalPrice}
            onRow={(rec) => {
                return !stockVat( rec ) ? {
                    style: { backgroundColor: '#f0f0f0', color: '#888686'}
                } : { } 
            }}
        />
    </>);

    const modalFooter = (<> 
        <Row>
            <Col span={24}>{/* Ignore */}</Col>
            <Col span={24}>
                <Flex justify="flex-end" gap={4}>
                    { !!dataHeader?.has_inv && <>
                        <Button 
                            className={`bn-center bn-secondary-outline`} 
                            onClick={()=>handleCancel(dataHeader.taxinvoice_id)}  
                        >ยืนยันการยกเลิก</Button>

                        <Button
                            icon={<TbReceipt />}  
                            className='bn bn-primary-outline'
                            onClick={()=>handlePrintTaxInvoice(dataHeader.rpocode)}
                        >
                            <Typography.Text>พิมพ์ใบเสร็จ</Typography.Text>
                        </Button> 
                    </>
                    }
                    { !dataHeader?.has_inv && 
                        <Button
                            className="bn-center bn-primary"
                            icon={<TbCheck />}
                            form="form-tax-invoice"
                            htmlType='submit'
                            // onClick={() => handleConfirm()}
                            disabled={!dataListValid}
                        >สร้างใบกำกับภาษี</Button>
                    }

                </Flex>
            </Col>
        </Row> 
    </>);

    const totalPrice = (<>
        <Table.Summary.Row>
            <Table.Summary.Cell colSpan={5} rowSpan={3} className='!bg-white align-top'>
            {/* <label>หมายเหตุ</label> */}
            <pre 
                className='border-0 italic' 
                style={{fontSize: 'clamp(11px, 0.7vw, 13px)',padding: '8px 10px'}} 
            >{dataHeader?.remark}</pre>
            </Table.Summary.Cell>
            <Table.Summary.Cell colSpan={2} align='end' className='!pe-3 align-top'>รวมเงินทั้งสิ้น</Table.Summary.Cell>
            <Table.Summary.Cell className='!pe-3 text-end border-right-0 align-top' style={{borderRigth:"0px solid"}} >
                <Typography.Text className='text-blue-600s text-nowrap !px-0'>{ comma(Number(dataHeader?.price_total || 0),2,2) } บาท</Typography.Text>
            </Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row>
            <Table.Summary.Cell colSpan={2} align='end' className='!pe-3'>ภาษีมูลค่าเพิ่ม { dataHeader?.vat > 0 && '(7%)'}</Table.Summary.Cell>
            <Table.Summary.Cell className='!pe-3 text-end border-right-0' style={{borderRigth:"0px solid"}} > 
                <Typography.Text className='text-blue-600s text-nowrap !px-0'>{ comma(Number(dataHeader?.vat || 0),2,2) } บาท</Typography.Text>
            </Table.Summary.Cell>
        </Table.Summary.Row> 
        <Table.Summary.Row>
            <Table.Summary.Cell colSpan={2} align='end' className='!pe-3'>ยอดรวมสุทธิ</Table.Summary.Cell>
            <Table.Summary.Cell className='!pe-3 text-end border-right-0' style={{borderRigth:"0px solid"}} > 
            <Typography.Text className='text-blue-600s text-nowrap !px-0'>{ comma(Number(dataHeader?.grand_total || 0),2,2) } บาท</Typography.Text> 
            </Table.Summary.Cell> 
        </Table.Summary.Row>
    </>)
    return (
        <>
        <div className='modal-tax-invoice-area' id="modal-area">
            <Modal
                open={openModal}
                title={modalTitle}
                afterClose={() => handleClose() }
                onCancel={() => setOpenModal(false) } 
                maskClosable={false}
                style={{ top: 0, height: 'calc(100vh - 10px)'  }}
                width={940}
                className='modal-tax-invoice mymodal'
                footer={<>{modalFooter}</>}
            >
            <Spin spinning={loading} >
                <div style={containerStyle}> 
                    <Space direction="vertical" size="middle" style={{ display: 'flex', position: 'relative'}} className='current'  >
                        <Card style={{backgroundColor:'#f0f0f0' }}> 
                            <Form form={form} layout="vertical" autoComplete="off" name='form-tax-invoice' onFinish={handleConfirm} onValuesChange={handleValuechange} >
                                {information_vat}
                                {information_cars}
                                {information_items}
                                {information_list}
                            </Form> 
                        </Card> 
                    </Space>       
                </div> 
            </Spin>
            </Modal>                
        </div>

        </>
    )
}

