/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { QctPageLayout } from '../../components/layout';
import { TbBasketCheck, TbCheck } from "react-icons/tb";
import { Button,  Typography, message } from 'antd';
import dayjs from "dayjs";
import RepairOrderManageForm from './MyManageForm';
import ReapirOrderManageView from './MyManageView';

import { TaxInvoiceModal } from '../../components/modal/tax-invoice';

import RepairOrderService from "../../service/RepairOrder.service";
import { TbSearchProvider } from '../../store/context/table-search.context';
import { formData } from "./model";
import useConfirm from '../../store/hook/use-confirm.hook';
import { TbReceipt } from 'react-icons/tb';
import { CancelButton } from '../../components/drawer/cancel';
import TaxInvoiceService from '../../service/TaxInvoice.service';

const formName = "sup-form";
const apirequest = RepairOrderService();
const taxrequest = TaxInvoiceService();
function Manage() {
  const navigate = useNavigate();
  const location = useLocation();
  const confirm = useConfirm();
  const { config:{ mode, acname, code } } = location.state || { config: null };

  const [formValue, setFormValue] = useState(formData);
  const [taxsValue, setTaxsValue] = useState(formData);
  const [resultValidate, setResultValidate] = useState(false);
  

  const [openTaxInvoice, setOpenTacInvoice] = React.useState( false );

  const [actionButton, setActionButton] = React.useState( "create" );

  const handleSubmit = async (value) =>{ 
    try {  
      const payload = {...value, id:code};
      // console.log( payload );
      // return;
      // const action = mode === "create" ? apirequest.create(value) : apirequest.update(value);
      await apirequest[mode](payload);
      setFormValue({...value});
      
      if( actionButton !== "stock")
        message.success("Request create done.");

      // const result = mode === "update" ? await confirm.confirm({content: "คุณต้องการที่จะตัดสต๊อกเลยหรือไม่"}) : false;
      const result = actionButton === "stock";
      if(!!result) {
        handleSubmitStock(payload, !false);
      } else navigate(-1);
    } catch ( er ){
      const { data } = er.response;
      message.error(data?.option || "Error request.")
    }
  }

  const handleSubmitStock = async (value, confirmFlag = true) =>{ 
    try {  
      const payload = {...value};
      const result = confirmFlag ? await confirm.confirm({content: "คุณต้องการที่จะตัดสต๊อกหรือไม่"}) : true;
      if(!!result) {
        await apirequest.submit_stock(payload);
        setFormValue({...value}); 
         
        navigate(-1);
        message.success("Adjust stock done.");
      } 

    } catch ( er ){
      const { data } = er.response;
      message.error(data?.option || "Error request.")
    }
  }

  const handleCancel = async (v) => {
    try { 
      // const result = await confirm.confirm({content: 'คุณต้องการยกเลิกรายการหรือไม่'});
      // if(!!result){  
        await apirequest.deleted(v, code);
        navigate(-1);
        message.success("ยกเลิกใบสั่งสินค้าเรียบร้อย");
      // }
    } catch ( er ){
      console.log( er ); 
      const { data:{ option } } = er.response;
      message.error(`Error request. ${option}`);
    } 
    
  }

  const handleTaxInvoice = async () => {
    try {
      const res = await taxrequest.get(code);
      const { data:{ head, list, taxs } } = res.data; 
      setTaxsValue({head, list, taxs});
    } catch ( e ) {
      console.log( e );
      message.error("เกิดข้อผิดพลาด")
    }

    // console.log(res);

    setOpenTacInvoice(true);
  }

  const initeil = async () =>{
    try {
      if( mode !== "create" && !!code){
        const res = await apirequest.get(code);
        const { data:{ head, list, payment } } = res.data; 
        head.rpodate = !!head.rpodate ? dayjs(head?.rpodate) : null; 
        setFormValue({head, list, payment});
      } 
    } catch ( er ){
      console.log( er );
      message.error("error request.")
    }
  }

  useEffect( () => {

    initeil();
  }, [mode, code]);

  const acButton = (<>
  { mode !== "view" 
    ?<> 
      { !Number(formValue.head.stock_submit || 0 ) && mode === "update" && 
        <>
          <CancelButton value={handleCancel} text='ยกเลิกใบรายการซ่อม' />
          <Button
            icon={<TbBasketCheck style={{fontSize:'1rem'}}/>} 
            className='bn-center bn-success-outline'
            onClick={()=> setActionButton("stock")}
            form={formName}
            htmlType='submit'
            disabled={ !resultValidate }
          >ยืนยัน/ตัดสต๊อก</Button>

          <Button
              icon={<TbCheck style={{fontSize:'1rem'}}/>} 
              className='bn-center bn-primary' 
              onClick={()=> setActionButton("update")}
              form={formName}
              htmlType='submit'
              disabled={ !resultValidate }
          >ยืนยัน/บันทึก</Button>          
        </>
      }
      { !Number(formValue.head.stock_submit || 0 ) && mode === "create" &&
        <Button
            icon={<TbCheck style={{fontSize:'1rem'}}/>} 
            className='bn-center bn-primary' 
            onClick={()=> setActionButton("update")}
            form={formName}
            htmlType='submit'
            disabled={ !resultValidate }
        >ยืนยัน/บันทึก</Button>
      }
    </>
    :<> 
      { !!Number(formValue.head.stock_submit || 0 ) &&
        <Button
          icon={<TbReceipt style={{fontSize:'1.4rem'}} />}
          className='bn-center bn-warning-outline'
          onClick={()=>handleTaxInvoice()}
        >
          <Typography.Text>ใบกำกับภาษี</Typography.Text>
        </Button>
      }
      { !["ชำระเงินไม่ครบ","ชำระเงินครบแล้ว"].includes( formValue.head.status ) &&
        <CancelButton value={handleCancel} text='ยกเลิกใบรายการซ่อม' />
      }
    </>
  }
  </>); 

  return (<>
    <QctPageLayout title={`ใบรายการซ่อมสินค้า - ${acname}`} back={true} footerRight={acButton} hearderRight={acButton} >
    {mode === "view"
      ? <ReapirOrderManageView 
        initeial={formValue} 
        reload={ async (v) => { 
          // console.log( v );
          !!v && await initeil();
        }} 
        />
      : (<TbSearchProvider>
          <RepairOrderManageForm formName={formName} submit={handleSubmit} initeial={formValue} validate={(v) => setResultValidate(v)} mode={mode} action={actionButton}/>
        </TbSearchProvider>)
    } 
    </QctPageLayout> 

    { openTaxInvoice && <TaxInvoiceModal show={openTaxInvoice} close={ () => setOpenTacInvoice(false) } source={taxsValue} values={() => initeil()} />}

  </>)
}

export default Manage