/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom';
// import ReactDOMServer from "react-dom/server";
import { useReactToPrint } from 'react-to-print';
import thaiBahtText from 'thai-baht-text';
import "./MyPrint.css";
import logo from "../../../assets/images/logo_qct.png";
 
import { Button, Flex,  Table, Typography, message } from 'antd';
import { column } from './model';
import { v4 as uuidv4 } from 'uuid';
// import dayjs from "dayjs";
import { comma, dateToThaiFormat } from "../../../utils/util";
import { PiPrinterFill } from 'react-icons/pi';
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from 'antd';

// import BillingService from "../../../service/BillingNote.service";
// import RepairOrderService from "../../../service/RepairOrder.service";
import OptionService from "../../../service/Options.service";
import { TbFileTypePdf } from "react-icons/tb";
import useSearch from '../../../store/hook/use-search.hook';
import useStorage from '../../../store/hook/use-storage.hook';
// import jsPDF from 'jspdf';

// const apiservice = BillingService();
// const apiservice = RepairOrderService();
const optservice = OptionService();

function MyPrintPreview() {
    const { print, choose, code } = useParams();

    const stSearch = useSearch("billing-note-access");
    const stStorage = useStorage("billing-note-print");
    const { criteria, tbparams } = stSearch.gettingSearch()
    const selected = stStorage.gettingStorage(false) || [];

    const componentRef = useRef(null);
    const printRef = useRef(null);
    const handlePrint = useReactToPrint({
      documentTitle: "Print This Document",
      onBeforePrint: () => handleBeforePrint(),
      onAfterPrint: () => handleAfterPrint(),
      removeAfterPrint: true,
    });

    // const [hData, setHData] = useState({});
    // const [details, setDetails] = useState([]); 
    const [hCustomer, setHCustomer] = useState([]); 
    const [hBillings, setHBillings] = useState([]); 

    // const [packingCostSource, setPackingCostSource] = useState([]); 
    
    const [newPageContent, setNewPageContent] = useState([]);
    const columnDesc = column; 

    const [loading, setLoading] = useState(false);

    const handleAfterPrint = () => { 
        setNewPageContent([]);

        if( !!Number(print) ) {
            window.close();
        }   
    }

    const handleBeforePrint = (e) => {
        console.log("before printing...")
        // const newElement = document.createElement('div');
        // newElement.id = 'new-container'; // Optional: Set an ID for the new container
        // newElement.innerHTML = 'TEST';
        // Render the new component into the new container 
    
        // Replace the old container with the new container
        // componentRef.current.innerHTML = 'TEST'; 
    }

    const handleCheckMultiPages = async () => {
        const limitPage = 820; 
        return new Promise( r =>{
            // const head = document.querySelector("#raw .in-head");
            const pages = []; 
            for( const ix in hCustomer ) {
                const data = document.querySelector(`#raw-${ix} .in-data`);
                const table = document.querySelector(`#raw-${ix} .in-data #tb-data`);
                // const thead = table?.querySelector("thead");
                const mtbody = table?.querySelector("tbody");
                const mtfoot = table?.querySelector("tfoot");
                const row = mtbody?.querySelectorAll("tr");

                const samplesPage =  [];
                // console.log(head, table, tbody, row); 
                // console.log(componentRef.current);
                // let pageCount = 1;
                let hPageCheck = 0;
                let emlContent = [];
                for( let elm of row ){
                    const h = Number(window.getComputedStyle(elm).getPropertyValue('height')?.replace("px", ""));
                    if( (hPageCheck + h) > limitPage ){
                        // console.log( { hPageCheck } ); 
                        samplesPage.push([...emlContent, elm]); 
                        emlContent = [];
                        hPageCheck = 0;
                    } else { 
                        hPageCheck += h;
                        emlContent = [...emlContent, elm];
                    }

                    // console.log( h, hPageCheck );
                }
                if(emlContent.length > 0 ) samplesPage.push(emlContent);  

                const hfoot = Number(window.getComputedStyle(mtfoot).getPropertyValue('height')?.replace("px", ""));
                
                let index = 1;
                for( let rind in samplesPage ) {  
                    // const chead = head.cloneNode(true);
                    const cdata = data.cloneNode(true);
                    const table = cdata.querySelector("#tb-data");
                    // const thead = table?.querySelector("thead");
                    const tbody = table?.querySelector("tbody");
                    const tfoot = table?.querySelector("tfoot");
    
                    tbody.style.height = `${limitPage - hfoot}px`; 

                    tbody.innerHTML = `${ (samplesPage[rind].map( m => m.outerHTML )).join("") }`;

                    if( rind < (samplesPage.length - 1)) tfoot.remove();
                    const temp = document.createElement('div'); 

                    // temp.appendChild( chead );
                    temp.appendChild( cdata );
                    temp.classList.add("on-page")
                    pages.push({page:temp, head: hCustomer[ix], num: index++, key:ix});
                }
                // console.log( pages );
            }
            setNewPageContent( state => [ ...state, ...pages]);
            r(pages);                
        }) 
    } 

    const handlePrintMultiPagesPdf = () => { 
        setLoading(true);                
        // await handleCheckMultiPages();
        handleCheckMultiPages().then( (res) => { 

            setLoading(false);
        }).catch( err => {
            console.log( err );
            setLoading(false);
        });
    } 

    const handlePrintMultiPages = async () => {
        setLoading(true);
        handleCheckMultiPages().then( res => { 
            setTimeout( () => handlePrint(null, () => printRef.current ), 240 ); 
            setLoading(false); 
        });
    }

    useEffect( () =>  {
        const init = async () => {
            // console.log( choose, selected);
            if( !!choose && selected?.length < 1 ) return;
            const rpoArray = !!choose ? [...new Set(selected?.map( m => m.rpocode ))] : undefined;
            
            const parm = { ...criteria, rpo_array: rpoArray, nobncode: !choose && !code }

            if( !!code ) parm["bncode"] = code;


            const res = await optservice.optionsBillingNotePost( { criteria: parm, tbparams, p:"billingnote-print" } ).catch( err => {
              console.log(err);
              message.error("Error getting infomation for print.")
            });

            // console.log( res );
            const { data : { head, list } } = res.data;
            setHCustomer(head);
            setHBillings(list);
            // setHData( head ); 
            // setDetails( [...list] );
        }

        init();
        return () => {}
    }, []);

    useEffect( () => { 
        if( !!Number(print) && hBillings.length > 0 ) setTimeout( () => handlePrintMultiPages(), 140 );
        return () => {}
    }, [hBillings, print])

    const HeaderForm = ({...resProps}) => {
        return (<>
            <div className='print-head' style={{height:64}}> 
                <div className='print-title flex gap-5'> 
                    <div className='grow !pb-2'>
                        <img src={logo} alt="" style={{paddingInline: 6, height: '100%'}}  />  
                    </div>
                    <div className='flex grow-0 justify-end items-center' style={{width: 278}}>
                        <Flex className='mb-0 '>
                            <Typography.Title level={3} align='end' className='m-0 min-w-28 text-end'>ใบวางบิล</Typography.Title> 
                        </Flex> 
                    </div> 
                </div> 
            </div>
            <ContentHead {...resProps}/>  
        </>)
    }

    const FooterForm = ({page}) => {
        return (
            <div className='print-foot' style={{height:34}}>  
                <div className='print-title flex justify-end'>   
                    <Flex className='mb-0'>
                        {/* <Typography.Text className='text-sm min-w-8'>Page</Typography.Text> */}
                        {/* <Typography.Text className='text-sm' strong>{page}</Typography.Text>  */}
                    </Flex> 
                </div> 
            </div>   
        )
    }

    const Summary = (source) => {
        const totalPrice = source.reduce( (acc, val) => acc + Number(val.price_total), 0 );
        return <>
            <Table.Summary.Row style={{height:24}}>
                <Table.Summary.Cell colSpan={5} className='!align-top'></Table.Summary.Cell>
            </Table.Summary.Row>

            <Table.Summary.Row className='r-sum'>
                <Table.Summary.Cell colSpan={3} rowSpan={2} className='!align-top !ps-0'>
                    <Flex vertical gap={12}>
                        <Flex vertical gap={2}>
                            {/* <Typography.Text className='tx-title' strong>หมายเหตุ</Typography.Text>  */}
                            {/* <pre className='tx-info m-0'>หมายเหตุ :{hData?.remark}</pre> */}
                        </Flex> 
                    </Flex>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={1} className='text-summary text-start !align-top' >
                    <Typography.Text className='text-sm text-start'>รวมเงินทั้งสิ้น</Typography.Text>
                </Table.Summary.Cell> 
                <Table.Summary.Cell colSpan={2} className='text-summary text-end !align-top' >
                    <Typography.Text className='text-sm text-end'>{comma( Number(totalPrice | 0), 2, 2 )} บาท</Typography.Text>
                </Table.Summary.Cell> 
            </Table.Summary.Row>    

            <Table.Summary.Row className='r-sum rl'>
                <Table.Summary.Cell colSpan={3} className='!align-top text-center !ps-0 !pt-1 border-cell'>
                    <Typography.Text className='text-sm text-end'>({thaiBahtText(totalPrice)})</Typography.Text>
                </Table.Summary.Cell> 
            </Table.Summary.Row>

            <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2} className='!align-top'>{'\u00A0'}</Table.Summary.Cell>
            </Table.Summary.Row> 

            <Table.Summary.Row>
                <Table.Summary.Cell colSpan={8} className='!align-top !ps-0 !pt-8'>
                    <Flex className='w-full' gap={32} >
                        <Flex vertical className='w-1/3' style={{ gap:10}}>
                            <Flex gap={2}>
                                <Typography.Text className='tx-info' strong style={{ minWidth:48}}>ผู้ออกเอกสาร </Typography.Text> 
                                <Typography.Text className='tx-info' >{'\u00A0'}</Typography.Text>
                            </Flex>
                            <Flex gap={2}>
                                <div className="w-full" style={{height: 90, border:'1px solid var(---color--1)'}}>{'\u00A0'}</div>
                            </Flex>
                        </Flex> 
                        <Flex vertical className='w-1/3' style={{ gap:10}}>
                            <Flex gap={2}>
                                <Typography.Text className='tx-info' strong style={{ minWidth:48}}>ผู้รับบิล </Typography.Text> 
                                <Typography.Text className='tx-info' strong>{'\u00A0'}</Typography.Text>
                            </Flex>
                            <Flex gap={2}>
                                <div className="w-full" style={{height: 90, border:'1px solid var(---color--1)'}}>{'\u00A0'}</div>
                            </Flex>
                        </Flex>
                        <Flex vertical className='w-1/3' style={{ gap:10}}>
                            <Flex gap={2}>
                                <Typography.Text className='tx-info' strong style={{ minWidth:48}}>ผู้ตรวจสอบ </Typography.Text> 
                                <Typography.Text className='tx-info' strong>{'\u00A0'}</Typography.Text>
                            </Flex>
                            <Flex gap={2}>
                                <div className="w-full" style={{height: 90, border:'1px solid var(---color--1)'}}>{'\u00A0'}</div>
                            </Flex>
                        </Flex>
                    </Flex>
                </Table.Summary.Cell>
            </Table.Summary.Row>              

            <Table.Summary.Row>
                <Table.Summary.Cell colSpan={8} className='!align-top !ps-0 !pt-3'>
                    <Flex className='w-full' gap={32} >
                        <Flex vertical className='w-1/3' style={{ gap:10}}>
                            <Flex gap={2} justify='space-between' className='w-full font-bold'> 
                                ( <Typography.Text className='tx-info' strong>{'\u00A0'}</Typography.Text> )
                            </Flex>
                            <Flex gap={2} justify='space-between' className='w-full font-bold'> 
                                ( <Typography.Text className='tx-info' strong>{'\u00A0'}</Typography.Text> )
                            </Flex>
                        </Flex> 
                        <Flex vertical className='w-1/3' style={{ gap:10}}>
                            <Flex gap={2} justify='space-between' className='w-full font-bold'> 
                                ( <Typography.Text className='tx-info' strong>{'\u00A0'}</Typography.Text> )
                            </Flex>
                            <Flex gap={2} justify='space-between' className='w-full font-bold'> 
                                ( <Typography.Text className='tx-info' strong>{'\u00A0'}</Typography.Text> )
                            </Flex>
                        </Flex>
                        <Flex vertical className='w-1/3' style={{ gap:10}}>
                            <Flex gap={2} justify='space-between' className='w-full font-bold'> 
                                ( <Typography.Text className='tx-info' strong>{'\u00A0'}</Typography.Text> )
                            </Flex>
                            <Flex gap={2} justify='space-between' className='w-full font-bold'> 
                                ( <Typography.Text className='tx-info' strong>{'\u00A0'}</Typography.Text> )
                            </Flex>
                        </Flex>
                    </Flex>
                </Table.Summary.Cell>
            </Table.Summary.Row>              
        </>
    }

    const ContentHead = ({page, data}) => {
        return ( 
        <div className='content-head in-head flex flex-col'> 
            <div className='print-title flex pb-2'>
                <div className='flex ps-3 grow-0' style={{width:280}}>
                    <Flex className='mb-1.5' vertical >
                        <Typography.Text className='tx-title min-w-48' strong>ข้อมูลผู้ขาย</Typography.Text>
                        <Typography.Text className='tx-info' >คิวซีที ออโต้ (อ้อมใหญ่-สามพราน)</Typography.Text> 
                        <Typography.Text className='tx-info' >34/2 หมู่4 ถ.เพชรเกษม ต.อ้อมใหญ่ อ. สามพราน นครปฐม 73160</Typography.Text> 
                        <Typography.Text className='tx-info' >โทร. 02-8126353, 02-8126354</Typography.Text> 
                    </Flex>
                </div> 
                <div className='flex ps-3 grow-0' style={{width:280}}>
                    <Flex className='mb-1.5' vertical>
                        <Typography.Text className='tx-title min-w-48' strong>ข้อมูลลูกค้า</Typography.Text>
                        <Typography.Text className='tx-info'>{data?.cusname}</Typography.Text>
                        { data?.taxno && <Typography.Text className='tx-info'>Tax. {data?.taxno || ''}</Typography.Text> }
                        <Typography.Text className='tx-info'>{data?.address}</Typography.Text> 
                        { data?.contact_name && <Typography.Text className='tx-info'>ติดต่อ {data?.contact_name || ''}</Typography.Text> }
                        { data?.contact_tel && <Typography.Text className='tx-info'>โทร. {data?.contact_tel}</Typography.Text> }
                        { data?.contact_email && <Typography.Text className='tx-info'>อีเมล {data?.contact_email}</Typography.Text> }
                    </Flex>
                </div> 
                <div className='flex px-3 grow justify-end'>
                    <Flex className='mb-1.5 w-full' vertical>
                        <Typography.Text className='tx-title min-w-48' strong>ข้อมูลเอกสาร</Typography.Text>
                        <Flex justify='space-between'>
                            <Typography.Text className='tx-info' strong>เลขที่ใบวางบิล</Typography.Text> 
                            <Typography.Text className='tx-info'>{data?.bncode || ""}</Typography.Text>  
                        </Flex>
                        <Flex justify='space-between'>
                            <Typography.Text className='tx-info' strong>วันที่ใบวางบิล</Typography.Text> 
                            <Typography.Text className='tx-info'>{!!data?.bndate ? dateToThaiFormat(data?.bndate) : "" }</Typography.Text>  
                        </Flex>
                        <Flex justify='space-between'>
                            <Typography.Text className='tx-info' strong>วันครบกำหนดชำระ</Typography.Text> 
                            <Typography.Text className='tx-info'>{!!data?.payment_duedate ? dateToThaiFormat(data?.payment_duedate) : ""}</Typography.Text>  
                        </Flex>
                        {/* <Flex justify='space-between'>
                            <Typography.Text className='tx-info' strong>เงือนไขการชำระเงิน</Typography.Text> 
                            <Typography.Text className='tx-info'>{data?.payment}</Typography.Text>  
                        </Flex> */}
                        <Flex justify='space-between'>
                            <Typography.Text className='tx-info' strong>หน้า</Typography.Text> 
                            <Typography.Text className='tx-info'>{page}</Typography.Text>  
                        </Flex>
                    </Flex>
                </div> 
            </div>  
        </div>
        )
    }

    const ContentBody = ({head, list}) => {
        return ( 
        <div className='content-body in-data flex flex-col'> 
            <Table 
                id="tb-data"
                size='small'
                dataSource={list}
                columns={columnDesc()}
                pagination={false}
                rowHoverable={false}
                rowKey="id"
                bordered={false}
                locale = {{ emptyText: <span>No data available, please add some data.</span> }}
                onRow={(record, index)=>{ 
                    return { className: 'r-sub'}
                }}
                summary={Summary}
            />
        </div>
        )
    } 

    const Pages = ({id, head, list}) => (
        <div ref={componentRef} id={id} >
            <ContentData head={head} >
                {/* <ContentHead /> */}
                <ContentBody head={head} list={list} />
            </ContentData>
        </div>
    )

    const ContentData = ({ children, pageNum = 1, total = 1, head={} }) => {
        return ( 
            <div className='billing-note-pages flex flex-col'>
                <HeaderForm page={`${pageNum}/${total}`} data={head} />
                <div className='print-content grow'> 
                    {children}
                </div>
                <FooterForm />
            </div>
        )
    }

    return (
        <> 
            <div className='page-show' id="billing-note-print"> 
                { loading && <Spin fullscreen indicator={<LoadingOutlined  />} /> }
                <div className="title-preview flex gap-4"> 
                    { !Number(print) && <Button
                        className='bn-center  !bg-blue-400' 
                        onClick={() => { handlePrintMultiPages() }}
                        // onClick={() => { handlePrint(null, () => componentRef.current ); }}
                        icon={<PiPrinterFill style={{fontSize:'1.1rem'}} />}
                    >
                        PRINT
                    </Button>
                    }
                    { false && <Button
                        className='bn-center  !bg-red-600 text-white hover:!text-white hover:!border-red-800' 
                        onClick={() => { handlePrintMultiPagesPdf() }}
                        // onClick={() => { handlePrint(null, () => componentRef.current ); }}
                        icon={<TbFileTypePdf style={{fontSize:'1.1rem'}} />}
                    >
                        PDF
                    </Button>}
                </div>         
                    { hCustomer?.map( (m,i) =>  
                        <div className="layout-preview" key={uuidv4()} >
                            <Pages 
                            id={`raw-${i}`} 
                            head={m} 
                            list={
                                hBillings?.filter( f => {
                                    
                                    if( !!choose ){
                                        return  f?.cuscode === m?.cuscode
                                    } else if(!!code) {
                                        return f?.cuscode === m?.cuscode && (f?.bncode === m?.bncode)  

                                    }
                                    return f?.cuscode === m?.cuscode  
                                })
                            } /> 
                        </div> 
                    )}
                <div className='hidden' id="mypage">
                    <div ref={printRef} >
                        {newPageContent?.map( (page, i) => {
                            const { key } = page;
                            const totalPage = (newPageContent?.filter( f => f.key === key))?.length;
                        // console.log( page.innerHTML );
                        return ( 
                            <div key={i} className='on-page'>
                                <ContentData pageNum={page.num} total={totalPage} head={page.head} > 
                                    <div dangerouslySetInnerHTML={{ __html: page?.page?.innerHTML }}></div>
                                </ContentData>
                                {i < (newPageContent.length-1) && <div className='page-break'></div>}
                            </div>)
                        })}
                    </div>
                </div>
            </div>        
        </>


    )
}

export default MyPrintPreview