/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Checkbox, Col, DatePicker, Descriptions, Divider, Drawer, Flex, Form } from 'antd';
import { Input, InputNumber, message, Radio, Row, Spin, Table, Typography } from 'antd';
import React from 'react'; 
import { TableSearchValue } from '../../form';
import { listHeader, payment, payment_check, payment_credit_card, payment_discount, payment_method, payment_transfer } from './model.moule';
import { CloseOutlined } from '@ant-design/icons';

import "./MyDrawer.css";
import { comma } from '../../../utils/util';
import OptionService from "../../../service/Options.service.js"; 
import BillingPaymentService from "../../../service/BillingPayment.service.js"; 
import { CancelButton } from '../cancel/index.js';



const emptyText = { emptyText:<span>ไม่มีข้อมูลรายการ</span> };
const dividerProp = { orientation:"left", style:{marginBlock:10}, className:'!border-black' }; 

const optrequest = OptionService();
const apirequest = BillingPaymentService();
export default function MyDrawerView({source, show, value, close, getContainer=null}) {
    const [form] = Form.useForm();

    const [openDrawer, setOpenDrawer] = React.useState(show); 
    const [dataHeader, setDataHeader] = React.useState({}); 
    const [dataList, setDataList] = React.useState([]); 
    

    const [loading, setLoading] = React.useState(false); 
    const [reload, setReload] = React.useState(false); 

    const [drawerWidth, setDrawerWidth] = React.useState(75);

    const container = () => document.getElementById("billing-payment");

    const containerStyle = {
      position: 'relative', 
      height: 'calc( 100% - 24px )'
    };



    const headStyle = {
        borderBottom: `2px solid var(--success)`,
        backgroundColor: `var(--success)`, 
    }

    const bodyStyle = {
        border: `2px solid var(--success)`
    }

    const footStyle = {
        borderTop: `2px solid var(--success)`, 
    } 

    const handleCancel = () => {
        setOpenDrawer(false);
    }

    const handleFormValueChange = async (value, record) => {
        const f = {...dataHeader};
        const {paid_amount, cash_discount, other, transfer_fee, withholding } = record;
        const {payment_totals} = f;
        const payment_discount = Number( transfer_fee || 0 ) + Number( cash_discount || 0 ) + Number( withholding || 0 ) + Number( other || 0 );
        
        const payment_remain = Number( payment_totals || 0 ) - Number( paid_amount || 0 ) - payment_discount;
        
        const newData = {
            ...f,
            ...record,
            payment_discount,
            // payment_remain,
            transfer_fee,
            cash_discount,
            withholding,
            other,
        }
        // console.log( {  value, record, dataHeader });

        setDataHeader( newData );
        form.setFieldsValue( { 
            ...newData,  
            payment_remain_txt:comma(payment_remain, 2, 2),
            payment_discount_txt:comma(payment_discount, 2, 2),
        } );
        // console.log( {newData});
    }

    const handleRemove = async (v) => {
      try { 
        // const result = await confirm.confirm({content: 'คุณต้องการยกเลิกรายการหรือไม่'});
        // if(!!result){  
          const { uuid:code } = source;
          await apirequest.deleted({comment:v, code});
          
          message.success("ยกเลิกการชำระเรียบร้อย");
          setReload(true);
          handleCancel();
        // }
      } catch ( er ){
        console.log( er ); 
        const { data:{ option } } = er.response;
        message.error(`Error request. ${option}`);
      } 
      
    }

    const handleSetMessage = (content, option={}) => ({content: content, getContainer: container, ...option})

    const handleConfigMessageNoti = () => {
        message.config({
          top: 8,
          prefixCls: 'my-message',
          getContainer: container
        });
    } 

    React.useEffect( () => {
       const initeial = async() => {
            const { uuid } = source || {};
            const res = await optrequest.optionsBillingPayment({p:"detail", code:uuid});

            setDataList( res.data.data );
            // console.log( res );
        }

        initeial();

    }, [source])
    
    // Update width on window resize
    React.useEffect(() => {
        const updateDrawerWidth = () => {
            if (window.innerWidth < 600) {
                setDrawerWidth(100);
            } else if (window.innerWidth < 1024) {
                setDrawerWidth(75);
            } else {
                setDrawerWidth(60);
            }
        };
        updateDrawerWidth();
        window.addEventListener('resize', updateDrawerWidth);

        handleConfigMessageNoti();
        return () =>  {
            window.removeEventListener('resize', updateDrawerWidth);
            message?.destroy();
        }
    }, []);

    const method_check = (<>
        <Descriptions title={false} layout="vertical" size='small' column={{xs:1, sm:2, md:2, lg:2, xl:4, xxl:4}} items={payment_check(source)} />
    </>)

    const method_credit_card = (<>
        <Descriptions title={false} layout="vertical" size='small' column={{xs:1, sm:2, md:2, lg:2, xl:4, xxl:4}} items={payment_credit_card(source)} />
    </>)

    const method_transfer = (<>
        <Descriptions title={false} layout="vertical" size='small' column={{xs:1, sm:2, md:2, lg:2, xl:4, xxl:4}} items={payment_transfer(source)} />
    </>);

    const totalValue = (rec) => {  
        const remain = rec?.reduce( (a, v) => a += Number(v?.payment_remain || 0), 0 ) ;
        const totals = rec?.reduce( (a, v) => a += Number(v?.payment_totals || 0), 0 ) ;
        const amount = rec?.reduce( (a, v) => a += Number(v?.payment_amount || 0), 0 ) ;
        return(<>
            <Table.Summary.Row> 
                <Table.Summary.Cell colSpan={4} align='start' className='!pe-4 align-top' >รวมเงินทั้งสิ้น</Table.Summary.Cell>
                {/* <Table.Summary.Cell className='!pe-4 text-end border-right-0 align-top' >
                    <Typography.Text className='m-0'>{ comma(Number(totals || 0),2,2) }</Typography.Text>
                </Table.Summary.Cell>  */}
                <Table.Summary.Cell className='!pe-4 text-end border-right-0 align-top' >
                    <Typography.Text className='m-0'>{ comma(Number(amount || 0),2,2) }</Typography.Text>
                </Table.Summary.Cell> 
                {/* <Table.Summary.Cell className='!pe-4 text-end border-right-0 align-top' >
                    <Typography.Text className='m-0'>{ comma(Number(remain || 0),2,2) }</Typography.Text>
                </Table.Summary.Cell>  */}
            </Table.Summary.Row>
        </>);
    };

    const field_infomation = (<>
        <Card style={{ backgroundColor: '#f3f4f6'}} id="billing-payment-head-list">
            <TableSearchValue
                showtitle={false}
                multi={true}  
                notSelect={true}
                tbProps={{
                    rowKey:"id",
                    dataSource: dataList || [],
                    columns:listHeader(),
                    pagination: false,
                    locale:{...emptyText},
                    summary:totalValue,
                }}
            /> 
        </Card>
    </>);

    const field_payment_method = (<>
        <Descriptions title={false} layout="vertical" size='small' column={{xs:1, sm:2, md:2, lg:2, xl:4, xxl:4}} items={payment_method(source)} />
        { source?.payment_type === "เช็ค" && method_check  }
        { source?.payment_type === "บัตรเครดิต" && method_credit_card  }
        { source?.payment_type === "โอน" && method_transfer  }
    </>)

    const fields_payment = (<> 
        <Descriptions title={false} layout="vertical" size='small' column={{xs:1, sm:2, md:2, lg:2, xl:4, xxl:4}} items={payment(source)} />
    </>);

    const fields_discount = (<> 
        <Descriptions title={false} layout="vertical" size='small' column={{xs:1, sm:2, md:2, lg:2, xl:4, xxl:4}} items={payment_discount(source)} />    
    </>);

    const footer = (<>
        <Row gutter={[{xs:32, sm:32, md:32, lg:12, xl:12}, 8]} className='m-0'>
            <Col span={12} className='p-0'>
                <Flex gap={4} justify='start'>
                    <Button className='bn-center bn-secondary-outline min-w-24' onClick={() => handleCancel()} >ปิด</Button>
                </Flex>
            </Col>
            <Col span={12} className='p-0'>
                <Flex gap={4} justify='end'>
                    <CancelButton value={handleRemove} />
                    {/* <Button className={`bn-center bn-success-outline min-w-24`} onClick={()=>handleConfirm()} >ยืนยันการชำระ</Button> */}
                </Flex>
            </Col>
        </Row>
    </>);

    return (
        <>
            <Drawer
                title={<Flex  gap={3}>
                    <Typography.Text className='text-white m-0'>การชำระเงิน</Typography.Text>
                    { !!source?.payment_code && <Typography.Text className='text-white m-0'>เลขที่ {source?.payment_code}</Typography.Text>}
                </Flex>}
                closeIcon={<CloseOutlined className='text-white' />}
                onClose={()=>handleCancel()}
                open={openDrawer}
                key="billing-payment"
                id="billing-payment"
                footer={footer}
                styles={{ header: headStyle, content:bodyStyle, footer:footStyle }}
                width={`${drawerWidth}%`}
                afterOpenChange={(e) => {
                    if(!e){  
                        (typeof close === 'function') && close( reload );  
                    }
                }}
                maskClosable={false}
                getContainer={() => (!!getContainer && typeof getContainer === "function") ? getContainer() : false}
                push={false}
            >
                <div id="billing-payment-main" style={containerStyle}> 
                    <Spin spinning={loading}>
                        <Form form={form}  layout="vertical" autoComplete="off" onValuesChange={handleFormValueChange} > 
                            <Divider {...dividerProp}>ข้อมูลรายการ</Divider>
                            {field_infomation}

                            <Divider {...dividerProp}>ช่องทางการชำระ</Divider>
                            {field_payment_method}                    

                            <Divider {...dividerProp}>ข้อมูลการชำระ</Divider>
                            {fields_payment}                    

                            <Divider {...dividerProp}>ข้อมูลส่วนลด/หักค่าใช้จ่าย</Divider>
                            {fields_discount} 
                        </Form>                         
                    </Spin>
                </div>
            </Drawer>
        </>
    )
}
