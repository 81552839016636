import React from 'react'
import { Button, Input, message, notification, Space } from 'antd'
import { TbSearch } from "react-icons/tb";

import MyModal from './MyModal';
import { TbSearchProvider } from '../../../store/context/table-search.context';
export default function MyModalInput({ selected, onClose, placeholder=null, className="", id, value=null, disabled=false, getContainer=null }) {
    const [open, setOpen] = React.useState(false);
    const [valueData, setValueData] = React.useState(null);

    const handleChoosed = ( value, key ) => {
        // console.log( {key, value} );
        if( !!value )
            setValueData( value?.payment_name );
        // setOpen(false);
        !!selected && selected( value );
    }

    const handleOpenModal = () => {
        message.destroy(); 
        setOpen(true);
    }

    const handleCloseModal = (v) => { 
        message.destroy(); 
        notification.destroy();
        setOpen(false);


        if( typeof onClose === "function") {
            onClose(v);
        }
    }

    React.useEffect( () => {
        setValueData( value );
    }, [value])

    React.useEffect(()=>{

        return () => {
            message?.destroy();
        }
    }, [])
    return (
        <>
            <Space.Compact style={{ width: '100%'  }}>
                <Input disabled 
                    placeholder={ placeholder || 'เลือกรายการ'}
                    id={ id || "input-payment" } 
                    value={valueData || ""}
                    className={`input-30 ${className}`}
                />
                <Button 
                    type="primary" 
                    className='bn-center' 
                    icon={<TbSearch style={{fontSize: '1rem'}} />} 
                    onClick={handleOpenModal} 
                    style={{minWidth:40}} 
                    disabled={disabled}
                ></Button>
            </Space.Compact>

            { open && 
                <TbSearchProvider>
                    <MyModal show={open} values={handleChoosed} close={handleCloseModal} getContainer={getContainer} /> 
                </TbSearchProvider>
            }
        </>

    )
}
