import { Flex, Typography } from "antd";
import dayjs from "dayjs";
import { comma } from '../../../utils/util';

import "../../../assets/styles/banks.css";

// const onCellEdit = () => ({ 
//   onCell: (recoed, index) => ({
//     className: '', 
//   })
// });

// const cellNoEdit = (clickCell) => ({
//   onCell: (recoed, index) => ({
//     className: `field-edit ${!!recoed?.sub_item ? 'no-expend !bg-gray-200' : ''}`,
//     onClick:()=> (!!clickCell && !recoed?.sub_item) && clickCell(recoed, index)
//   })
// });

export const listColumns = () => [ 
    {        
        title: "ลำดับ",
        dataIndex: "index",
        key: "index",
        width: 64, 
        align: "left",
        render: (v, rex, ind) => <>{ String(ind + 1).padStart(2, '0') }</>
    },
    {
        title: "วันที่ชำระ",
        dataIndex: "paydate",
        key: "paydate",
        width: 120,
        render: (v) => dayjs(v).format("DD/MM/YYYY"),
    },     
    {        
        title: "ยอดชำระ",
        dataIndex: "payment_amount",
        key: "payment_amount",
        width: 120, 
        className: "!pe-3",
        align: "right",
    },
    {        
        title: "ค่าธรรมเนียมการโอน",
        dataIndex: "transfer_fee",
        key: "transfer_fee",
        width: 120, 
        className: "!pe-3",
        align: "right",
    },
    {        
        title: "ส่วนลดเงินสด",
        dataIndex: "cash_discount",
        key: "cash_discount",
        width: 120, 
        className: "!pe-3",
        align: "right",
    },
    {        
        title: "หัก ณ ที่จ่าย",
        dataIndex: "withholding",
        key: "withholding",
        width: 120, 
        className: "!pe-3",
        align: "right",
    },
    {        
        title: "อื่นๆ",
        dataIndex: "other",
        key: "other",
        width: 120, 
        className: "!pe-3",
        align: "right",
    },
    {        
        title: "ยอดรวม",
        dataIndex: "payment_amount_total",
        key: "payment_amount_total",
        width: 120, 
        className: "!pe-3",
        align: "right",
    },
    {        
        title: "หมายเหตุ",
        dataIndex: "payment_amount",
        key: "payment_amount",
        width: 120, 
        className: "!pe-3",
        align: "right",
    },

];

export const listHeader = () => [
    {        
        title: "ลำดับ",
        dataIndex: "index",
        key: "index",
        width: 64, 
        align: "left",
        render: (v, rex, ind) => <>{ind + 1}</>
    },
    {
        title: "เลขที่ใบรายการซ่อม",
        dataIndex: "rpocode",
        key: "rpocode",
        width: 150,
        // render: (v) => dayjs(v).format("DD/MM/YYYY"),
    }, 
    {
        title: "วันที่ใบรายการซ่อม",
        dataIndex: "rpodate",
        key: "rpodate",
        width: 150,
        render: (v) => dayjs(v).format("DD/MM/YYYY"),
    }, 
    {
        title: "ลูกค้า",
        dataIndex: "cusname",
        key: "cusname", 
    }, 
    // {
    //     title: "ราคารวม",
    //     dataIndex: "price_total",
    //     key: "price_total",
    //     className: '!pe-4',
    //     align: 'right',
    //     width: 120,
    //     render: (v) => comma(Number( v || 0 ), 2, 2)
    // }, 
    {
        title: "ยอดชำระ",
        dataIndex: "payment_amount",
        key: "payment_amount",
        className: '!pe-4',
        align: 'right',
        width: 120,

        render: (v) => comma(Number( v || 0 ), 2, 2)
    }, 
    // {
    //     title: "ยอดคงเหลือ",
    //     dataIndex: "payment_remain",
    //     key: "payment_remain",
    //     className: '!pe-4',
    //     align: 'right',
    //     width: 120,
    //     render: (v) => comma(Number( v || 0 ), 2, 2)
    // }, 
];

export const info = (val) => [
    {
      key: 'rpocode',
      label: 'เลขที่ใบรายการซ่อม',
      children: <Typography.Text>{val?.rpocode || '\u00A0'}</Typography.Text>,
    //   span: { xl: 1, xxl: 1 }
      // labelStyle:lableDesStyle
    },
    {
      key: 'rpodate',
      label: 'วันที่ใบรายการซ่อม', 
      children: <Typography.Text>{dayjs(val?.rpodate).format("DD/MM/YYYY") || '\u00A0'}</Typography.Text>, 
    //   span: { xl: 3, xxl: 3 }
      // labelStyle:lableDesStyle
    },    
    {
      key: 'cuscode',
      label: 'รหัสลูกค้า',
      children: <Typography.Text>{val?.cuscode || '-'}</Typography.Text>,
    //   span: { xl: 1, xxl: 1 }
      // labelStyle:lableDesStyle
    },
    {
      key: 'cusname',
      label: 'ชื่อลูกค้า', 
      children: <Typography.Text>{val?.cusname || '-'}</Typography.Text>, 
    //   span: { xl: 3, xxl: 3 }
      // labelStyle:lableDesStyle
    },
    {
      key: 'taxnumber',
      label: 'เลขผู้เสียภาษี', 
      children: <Typography.Text>{val?.taxnumber || '-'}</Typography.Text>,
      // labelStyle:lableDesStyle
    },
    {
      key: 'contact_name',
      label: 'ติดต่อ', 
      children: <Typography.Text>{val?.contact_name || '-'}</Typography.Text>,
      // labelStyle:lableDesStyle
    },
    {
      key: 'contact_tel',
      label: 'เบอร์โทร', 
      children: <Typography.Text>{val?.contact_tel || '-'}</Typography.Text>,
      // labelStyle:lableDesStyle
    },
    {
      key: 'contact_email',
      label: 'อีเมล', 
      children: <Typography.Text>{val?.contact_email || '-'}</Typography.Text>,
      // labelStyle:lableDesStyle
    },
    {
      key: 'address',
      label: 'ที่อยู่ลูกค้า', 
      children: <Typography.Text className='text-wrap'>{val?.address || '-'}</Typography.Text>,
    },
];

export const payment_method = (val) => [
  {
    key: 'payment_cond',
    label: 'รูปแบบการชำระ',
    children: <Typography.Text>{val?.payment_cond}</Typography.Text>,
  },
  {
    key: 'payment_date',
    label: 'วันที่ชำระ', 
    children: <Typography.Text>{dayjs(val?.payment_date).format("DD/MM/YYYY")}</Typography.Text>, 
  },
  {
    key: 'payment_method1',
    label: '', 
    children: <Typography.Text>{'\u00A0'}</Typography.Text>,  
  },
  {
    key: 'payment_method2',
    label: '', 
    children: <Typography.Text>{'\u00A0'}</Typography.Text>, 
  },
];

export const payment_check = (val) => [
  {
    key: 'check_bank',
    label: 'ธนาคาร', 
    children: <>
      <Flex align='center' gap={8}>
          <i className={`bank bank-${val.check_bank} shadow huge`} style={{height:24, width:24}}></i>
          <Flex align='start' gap={1} vertical>
              {/* <Typography.Text ellipsis style={{ fontSize: 13 }}>{v.thai_name}</Typography.Text>  */}
              <Typography.Text ellipsis={true} style={{ fontSize: 'clamp(10px, .9vw, 14px)', }}>{val.check_bank_name}</Typography.Text> 
          </Flex>
      </Flex>
    </>,
  },
  {
    key: 'check_bank_branch',
    label: 'สาขาธนาคาร',
    children: <Typography.Text>{val.check_bank_branch}</Typography.Text>, 
  },
  {
    key: 'check_number',
    label: 'เลขที่เช็ค',
    children: <Typography.Text>{val.check_number}</Typography.Text>, 
  },
  {
    key: 'check_date',
    label: 'วันที่ในเช็ค',
    children: <Typography.Text>{dayjs(val?.check_date).format("DD/MM/YYYY")}</Typography.Text>, 
  },
  // {
  //   key: 'payment_check1',
  //   label: '', 
  //   children: <Typography.Text>{'\u00A0'}</Typography.Text>,  
  // },
  // {
  //   key: 'payment_check2',
  //   label: '', 
  //   children: <Typography.Text>{'\u00A0'}</Typography.Text>, 
  // },
];

export const payment_credit_card = (val) => [
  {
    key: 'credit_card_bank',
    label: 'ธนาคาร',
    children: <>
      <Flex align='center' gap={8}>
          <i className={`bank bank-${val.credit_card_bank} shadow huge`} style={{height:30, width:30}}></i>
          <Flex align='start' gap={1} vertical>
              {/* <Typography.Text ellipsis style={{ fontSize: 13 }}>{v.thai_name}</Typography.Text>  */}
              <Typography.Text ellipsis={true} style={{ fontSize: 'clamp(10px, .9vw, 14px)', }}>{val.credit_card_bank_name}</Typography.Text> 
          </Flex>
      </Flex>
    </>,
  },
  {
    key: 'credit_card_payment',
    label: 'การชำระ', 
    children: <Typography.Text>{val.credit_card_payment} {val.credit_card_payment === "ผ่อนชำระ" && `${val?.credit_card_installment || 0} เดือน` }</Typography.Text>, 
  },
];

export const payment_transfer = (val) => [
  {
    key: 'transfer_bank',
    label: 'ธนาคาร',
    children: <>
      <Flex align='center' gap={8}>
          <i className={`bank bank-${val.transfer_bank} shadow huge`} style={{height:30, width:30}}></i>
          <Flex align='start' gap={1} vertical>
              {/* <Typography.Text ellipsis style={{ fontSize: 13 }}>{v.thai_name}</Typography.Text>  */}
              <Typography.Text ellipsis={true} style={{ fontSize: 'clamp(10px, .9vw, 14px)', }}>{val.transfer_bank_name}</Typography.Text> 
          </Flex>
      </Flex>
    </>,
  },
  {
    key: 'transfer_bank_branch',
    label: 'สาขาธนาคาร', 
    children: <Typography.Text>{val.transfer_bank_branch}</Typography.Text>, 
  },
  {
    key: 'transfer_refnumber',
    label: 'เลขอ้างอิง',
    children: <Typography.Text>{val.transfer_refnumber}</Typography.Text>, 
  },
];

export const payment_discount = (val) => [
  {
    key: 'transfer_fee',
    label: 'ค่าธรรมเนียมการโอน',
    children: <Typography.Text>{comma( Number( val?.transfer_fee) || "-" ) }</Typography.Text>,
  },
  {
    key: 'cash_discount',
    label: 'ส่วนลดเงินสด', 
    children: <Typography.Text>{comma( Number( val?.cash_discount) || "-")}</Typography.Text>, 
  },    
  {
    key: 'withholding',
    label: 'หัก ณ ที่จ่าย', 
    children: <Typography.Text>{comma( Number( val?.withholding) || "-")}</Typography.Text>, 
  },    
  {
    key: 'other',
    label: 'อื่นๆ',
    children: <Typography.Text>{comma( Number( val?.other) || "-")}</Typography.Text>,
  },
  {
    key: 'remark',
    label: 'หมายเหตุ',
    children: <Typography.Text>{val?.remark}</Typography.Text>,
  },
];

export const payment = (val) => [
  {
    key: 'payment_totals',
    label: 'ยอดที่ต้องชำระ',
    children: <Typography.Text>{comma( Number( val?.payment_totals || 0))}</Typography.Text>,
  },
  {
    key: 'paid_amount',
    label: 'ยอดชำระ', 
    children: <Typography.Text>{comma( Number( val?.payment_amount || 0))}</Typography.Text>, 
  },    
  {
    key: 'payment_discount',
    label: 'ส่วนลด/หักค่าใช้จ่าย', 
    children: <Typography.Text>{comma( Number( val?.payment_discount || 0))}</Typography.Text>, 
  },    
  {
    key: 'payment_remain',
    label: 'ยอดชำระคงเหลือ',
    children: <Typography.Text>{comma( Number( val?.payment_remain || 0))}</Typography.Text>,
  },
];

export const formDefault = {
  id:null,
  payment_amount: null,
  payment_totals: null,
  payment_remain: null,
  transfer_fee: null,
  cash_discount: null,
  withholding: null,
  other: null,
  remark: null,
  // created_date: null,
  // created_by: null,
  payment_date: null,
  payment_discount: null,
  payment_cond: null,
  check_bank: null,
  check_bank_name: null,
  check_number: null,
  check_date: null,
  check_bank_branch: null,
  check_result: null,
  transfer_bank: null,
  transfer_bank_name: null,
  transfer_bank_branch: null,
  transfer_refnumber: null,
  credit_card_bank: null,
  credit_card_bank_name: null,
  credit_card_payment: null,
  credit_card_installment: null,
}